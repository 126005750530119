import { Component, OnInit, Inject } from '@angular/core';
import { User } from 'src/app/models/user';
import { CommonService } from 'src/app/service/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Usercontribution } from 'src/app/models/usercontribution';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { error_messege } from 'src/app/models/error_massege'
import { Setting } from 'src/app/models/setting';
import * as lodash from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-contributiondailogn',
  templateUrl: './contributiondailogn.component.html',
  styleUrls: ['./contributiondailogn.component.css']
})
export class ContributiondailognComponent implements OnInit {

  user: User;
  users: User[] = [];
  usercontribution: Usercontribution;
  usercontributions: Usercontribution[];
  state: string = "add";
  fg: FormGroup;
  paymentyear: any[];
  PaymentMonth: any[];
  error_messege = error_messege;
  settings: Setting;
  newarray: any[];
  newarray2: any[];
  months = [];
  constructor(
    public dialogRef: MatDialogRef<ContributiondailognComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cs: CommonService,
    private fb: FormBuilder,
    private ngxloader: NgxUiLoaderService
  ) {
    this.newarray = [];
    this.newarray2 = [];

    dialogRef.disableClose = true;
    this.cs.fillSession();
    this.fg = this.fb.group({
      UserId: ['', [Validators.required]],
      GroupId: [this.cs.userSession.GroupID],
      UserContribution1: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      Paymentdate: ['', [Validators.required]],
      PaymentMonth: ['', [Validators.required]],
      PaymentYear: ['', [Validators.required]],
      ContributionType: ['Savings'],
      MonthNo: [''],
      Penalty: ['']


    })
  }

  ngOnInit() {


    this.Bindusers();
    this.usercontribution = this.data.value;
    if (this.usercontribution.Id == 0)
      this.state = "add";
    else {
      this.state = "edit";
      this.fg.patchValue({
        UserId: this.usercontribution.UserId,
        GroupId: this.cs.userSession.GroupID,
        UserContribution1: this.usercontribution.UserContribution1,
        Paymentdate: this.usercontribution.Paymentdate,
        PaymentMonth: this.usercontribution.PaymentMonth,
        PaymentYear: this.usercontribution.PaymentYear,
        ContributionType: this.usercontribution.ContributionType,
        Penalty: this.usercontribution.Penalty
      });
    }
    this.cs.getSetting().subscribe((res1: any) => {
      this.settings = res1.ReturnObject;
      var startDate = new Date(this.settings[0].StartDate);
      var endDate = new Date(this.settings[0].EndDate);
      const fromYear = startDate.getFullYear();
      const fromMonth = startDate.getMonth();
      const toYear = endDate.getFullYear();
      const toMonth = endDate.getMonth();
      // const months = [];
      for (let year = fromYear; year <= toYear; year++) {
        let month = year === fromYear ? fromMonth : 0;
        const monthLimit = year === toYear ? toMonth : 11;
        for (; month <= monthLimit; month++) {
          this.months.push({ year, month: CommonService.PaymentMonth[month] })
        }
      }
      this.paymentyear = [...new Set(this.months.map(obj => obj.year))]//months.map(a => a.year).;

      if (this.state == "edit") {
        this.PaymentMonth = this.months.filter(x => x.year == this.fg.value.PaymentYear).map(a => a.month);
      }
    });

  }

  onyearchange(event: any) {
    this.PaymentMonth = this.months.filter(x => x.year == event.value).map(a => a.month);
  }

  get f() {
    return this.fg;
  }

  Bindusers() {
    this.cs.userlist().subscribe((users: any) => {
      this.users = users.ReturnObject;
      this.users.sort((a, b) => (a.FirstName > b.FirstName) ? 1 : -1);
    })
  }

  onclick() {
    var contribution: Usercontribution = this.fg.value;
    var mon = CommonService.PaymentMonth.filter(x => x.viewValue == contribution.PaymentMonth)[0];
    contribution.MonthNo = mon.value;
    var uid = contribution.UserId;
    var mno = contribution.MonthNo;
    var year = contribution.PaymentYear;


    // contribution.Paymentdate = contribution.Paymentdate.toLocaleDateString();
    if (this.state == "add") {

      this.cs.contributionCheck(uid, mno, year).subscribe((res: any) => {
        this.ngxloader.start();
        if (res == true) {
          this.cs.showSnackBar('danger', 'user has already contributed for ' + contribution.PaymentMonth);
          this.ngxloader.stop();
        } else {
          this.cs.contributionRegister(contribution).subscribe((res: any) => {
            this.cs.showSnackBar('success', 'User Record Added successfully');
            this.dialogRef.close();
            this.ngxloader.stop();
          }, error => {
            this.ngxloader.stop();
            this.cs.showSnackBar('danger', 'Error Occured : Record Not Created ');
          })

        }

      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured');
      })


    } else {
      this.state = "edit";
      contribution.Id = this.usercontribution.Id;
      this.cs.contributionUpdate(contribution).subscribe((res: any) => {
        this.ngxloader.start();
        if (res) {
          this.cs.showSnackBar('success', 'User Record Updated successfully');
          this.ngxloader.stopAll();
        } else {
          this.cs.showSnackBar('danger', 'User Record Not Updated successfully');
          this.ngxloader.stopAll();
        }
        this.dialogRef.close();

      }, error => {
        this.ngxloader.stopAll();
        this.cs.showSnackBar('danger', 'Error Occured : User Record Not Updated');
      })
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
