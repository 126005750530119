import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { MatTableDataSource } from '@angular/material';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-penaltyreport',
  templateUrl: './penaltyreport.component.html',
  styleUrls: ['./penaltyreport.component.css']
})
export class PenaltyreportComponent implements OnInit {

  penalty: any[];
  dataSource: any;
  displayedColumns: string[] = [];
  constructor(private cs: CommonService, private ngxloader: NgxUiLoaderService) { }

  ngOnInit() {
    
    
    this.cs.penaltyeport().subscribe((res: any) => {
      this.ngxloader.start();
      this.penalty = res;
      this.displayedColumns = [];
      for (let v in this.penalty[0]) {
        this.displayedColumns.push(v);
      }

      // INITIALIZE MatTableDataSource
      this.dataSource = new MatTableDataSource(this.penalty);
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }

  exportAsXLSX(): void {
    this.cs.exportAsExcelFile(this.penalty, 'penalty');
  }

}
