import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Role } from 'src/app/models/roles';
import { RolesdailogComponent } from '../rolesdailog/rolesdailog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'src/app/service/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-loanaction',
  templateUrl: './loanaction.component.html',
  styleUrls: ['./loanaction.component.css']
})
export class LoanactionComponent implements OnInit {

  fg: FormGroup;
  detail: any;
  role: Role = new Role();
  roles: Role[] = [];
  state: string = "add";
  constructor(public dialogRef: MatDialogRef<RolesdailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private cs: CommonService, private ngxloader: NgxUiLoaderService) {
    dialogRef.disableClose = true;
    this.cs.fillSession();
    this.fg = this.fb.group({
      Comment: ['']
    })
  }

  ngOnInit() {

    this.detail = this.data.value;

  }


  approval() {

    var aid = this.cs.userSession.Id;
    var lid = this.detail.Id;
    var comment = this.fg.value.Comment;
    var loanApproveStatus = true;
    this.cs.GetLoanApproval(aid, lid, comment, loanApproveStatus).subscribe((res: any) => {
      if (res.IsError == 1) {
        this.cs.showSnackBar('danger', res.DetailsError);
        this.dialogRef.close();
      }
    })
  }

  reject() {
    var aid = this.cs.userSession.Id;
    var lid = this.detail.Id;
    var comment = this.fg.value.Comment;
    var loanApproveStatus = false;
    this.cs.GetLoanApproval(aid, lid, comment, loanApproveStatus).subscribe((res: any) => {
      if (res.IsError == 1) {
        this.cs.showSnackBar('danger', res.DetailsError);
        this.dialogRef.close();
      }
    })
  }

  cancel() {
    this.dialogRef.close();
  }


}
