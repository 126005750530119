import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Usercontribution } from '../../models/usercontribution'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { error_messege } from 'src/app/models/error_massege'
import { Loanrepayment } from 'src/app/models/loanrepayment';
import { LoanCompleteMsgComponent } from '../loan-complete-msg/loan-complete-msg.component';


@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.css']
})
export class PayComponent implements OnInit {
  user: any;
  loanrepay: any;
  interestpaid: any;
  loanRepay: FormGroup;
  loanInterest: FormGroup;
  selecteduser: any;
  loanid: any;
  loanid1: any;
  loanoutstanding: any;
  interestowned: any;
  interestrate: any;
  // state: string = "";
  error_messege = error_messege;
  usercontributions: Usercontribution[] = [];
  users: any[] = [];
  paymentyear: any[];
  PaymentMonth: any[];
  loanrepayment: Loanrepayment[] = [];
  loantype: any;
  datavalue: any = 0;
  pdata: any = 0;
  idata: any = 0;
  loano: any;
  loani: any;
  name: string;
  TotalPaid: number = 0;
  constructor(public dialogRef: MatDialogRef<PayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private cs: CommonService, private fb: FormBuilder, private ngxloader: NgxUiLoaderService, private dailog: MatDialog) {
    dialogRef.disableClose = true;
    this.paymentyear = CommonService.PaymentYear;
    this.PaymentMonth = CommonService.PaymentMonth;
    this.cs.fillSession();
    this.selecteduser = this.data.loandetail[0].MstUser.Id;
    this.loanid = this.data.loandetail[0].LoanId;
    this.name = this.data.loandetail[0].MstUser.FirstName + " " + this.data.loandetail[0].MstUser.LastName;
    this.loanRepay = this.fb.group({
      UserId: [''],
      LoanId: [this.loanid],
      GroupId: [''],
      Paymentdate: ['', [Validators.required]],
      LoanOutstanding: [''],
      InterestOwned: [''],
      InterestRate: [''],
      InterestPaid: [''],
      LoanRepay: [''],
      MonthNo: [''],
      repaymentTemp: ['', [Validators.required, Validators.pattern(/^(\d*\.)?\d+$/)]]
    });
  }

  ngOnInit() {
    if (this.data.loandetail[0] == null) {
    } else {
      this.cs.getSetting().subscribe((res1: any) => {
        this.ngxloader.start();
        this.loantype = res1.ReturnObject[0].LoanType;
        this.loanid1 = this.data.loandetail[0].LoanId;
        // this.state = this.loantype == 'Fixed Interest' ? "fix" : "reduce";

        this.cs.getpaymentrecord(this.selecteduser, this.loanid).subscribe((res: any) => {
          this.ngxloader.start();
          if (res.ReturnObject != null && res.ReturnObject.length > 0) {
            this.loanoutstanding = res.ReturnObject[0].LoanOutstanding;
            this.interestowned = res.ReturnObject[0].InterestOwned;
            this.interestrate = res.ReturnObject[0].InterestRate;
            this.loanid1 = res.ReturnObject[0].LoanId;
          }
          else {
            this.loanoutstanding = this.data.loandetail[0].LoanOutstanding;
            this.interestowned = this.data.loandetail[0].InterestOwned;
            this.interestrate = this.data.loandetail[0].InterestRate;
            this.loanid1 = this.data.loandetail[0].LoanId;
          }

          this.loanRepay.patchValue({
            UserId: this.name,
            LoanId: this.loanid1,
            GroupId: this.data.loandetail[0].GroupId,
            Paymentdate: '',
            LoanOutstanding: this.loanoutstanding,
            InterestOwned: this.interestowned,
            InterestRate: this.interestrate,
            InterestPaid: this.interestpaid,
            LoanRepay: this.loanrepay,
            repaymentTemp: '',
          });
          this.ngxloader.stop();
        }, error => {
          this.ngxloader.stop();
          this.cs.showSnackBar('danger', 'Error Occured');
        })
        this.ngxloader.stop();
      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured');
      })
    }

    this.cs.userlist().subscribe((response1: any) => {
      this.users = response1.ReturnObject
    })
  }

  get f() {
    return this.loanRepay;
  }


  add() {
    var loanrepayments = this.loanRepay.value;
    var m = loanrepayments.Paymentdate._i.month + 1
    var mon: any = CommonService.PaymentMonth.filter(x => x.value == m)[0];
    loanrepayments.MonthNo = mon.value;
    loanrepayments.UserId = this.selecteduser;
    loanrepayments.GroupId = this.data.loandetail[0].GroupId;
    var uid = loanrepayments.UserId;
    var year = loanrepayments.Paymentdate._i.year;

    this.cs.loanCheck(uid, loanrepayments.MonthNo, year, this.loanid).subscribe((res: any) => {
      this.ngxloader.start();
      if (res == true) {
        this.cs.showSnackBar('danger', 'User has already paid loan instalment for ' + mon.viewValue);
      } else {
        this.cs.UserLoandetailsCreate(loanrepayments).subscribe((res1: any) => {
          if (res1) {
            if (res1.LoanOutstanding == 0) {
              const dailogpopup = this.dailog.open(LoanCompleteMsgComponent, {

                // data: { value: this.loandetail }
              });
              this.cs.showSnackBar('success', 'Payment Add successfully');
              this.dialogRef.close();
              dailogpopup.afterClosed().subscribe(result => {

              });
            } else {
              this.cs.showSnackBar('success', 'Payment Add successfully');
              this.dialogRef.close();
            }
          } else {
            this.cs.showSnackBar('danger', 'Error Occured : Failed Payment ');
          }
        })
      }
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }

  onchangeloanrepayInterest() {
    var obj = this.loanRepay.value;
    var calulatedInterest;
    var loanout;
    var calulatedInterestown;
    var loanint = (this.loanRepay.value.repaymentTemp > this.data.loandetail[0].InterestOwned)
      ? ((this.loanRepay.value.repaymentTemp - this.data.loandetail[0].InterestOwned) > this.data.loandetail[0].InterestOwned
        ? this.data.loandetail[0].InterestOwned : this.loanRepay.value.repaymentTemp - this.data.loandetail[0].InterestOwned)
      : (this.loanRepay.value.repaymentTemp);
    calulatedInterest = loanint;
    obj.LoanRepay = (this.loanRepay.value.repaymentTemp - loanint) < 0
      ? loanint : (this.loanRepay.value.repaymentTemp - loanint);
    obj.InterestRate = obj.InterestRate == "" ? 0 : obj.InterestRate;
    loanout = this.data.loandetail[0].LoanOutstanding - obj.LoanRepay;
    if (this.loantype.toLowerCase() == String('Fixed Interest').toLowerCase()) {
      calulatedInterestown = (this.data.loandetail[0].InterestOwned - calulatedInterest);
    }
    else {
      calulatedInterestown = (loanout * (obj.InterestRate / 100)) + (this.data.loandetail[0].InterestOwned - loanint);
    }

    this.datavalue = calulatedInterest;
    this.pdata = obj.LoanRepay;
    this.idata = calulatedInterest;
    this.loano = loanout;
    this.loani = calulatedInterestown;

    this.loanRepay.patchValue({
      InterestPaid: calulatedInterest,
      InterestOwned: calulatedInterestown,
      LoanOutstanding: loanout,
      LoanRepay: this.pdata
    });
    this.TotalPaid = this.loanRepay.value.repaymentTemp;
  }

  cancel() {
    this.dialogRef.close();
  }
}
