import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Setting } from 'src/app/models/setting';
import { Loandetail } from 'src/app/models/loandetails';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { error_messege } from 'src/app/models/error_massege'
import moment from 'moment';
import { LoanapplicationdailogComponent } from 'src/app/shared/loanapplicationdailog/loanapplicationdailog.component';

@Component({
  selector: 'app-applyloan',
  templateUrl: './applyloan.component.html',
  styleUrls: ['./applyloan.component.css']
})
export class ApplyloanComponent implements OnInit {
  error_messege = error_messege;
  fg: FormGroup;
  loandetail: Loandetail = new Loandetail();
  loandetails: Loandetail[] = [];
  loaninterestrate: any;
  // state: string = "default";
  paymentYear: any[];
  paymentMonth: any[];
  datavalue: any = 0;
  pdata: any = 0;
  idata: any = 0;
  interestrate: any;
  displayedColumns: string[] = ['Id', 'FirstName', 'Tenure', 'Principal', 'InterestRate', 'LoanStartDate', 'LoanEndDate', 'OutstnadingPrincipal', 'OutstandingInterest', 'TotalInterest', 'Comments', 'LoanStatus', 'Approvalstatus', 'action'];
  dataSource: MatTableDataSource<Loandetail>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(private cs: CommonService, private fb: FormBuilder, private dailog: MatDialog, private route: Router, private ngxloader: NgxUiLoaderService) {
    this.cs.fillSession();
    this.paymentYear = CommonService.PaymentYear;
    this.paymentMonth = CommonService.PaymentMonth;
    //   this.cs.getSetting().subscribe((res:any)=>{
    //     this.interestrate = res.ReturnObject[0].InterestRate

    //   this.fg = this.fb.group({
    //     UserId: [this.cs.userSession.Id],
    //     GroupID: [this.cs.userSession.GroupID],
    //     InterestRate: [this.interestrate],
    //     Tenure: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    //     Principal: ['', [Validators.required, Validators.pattern(/^(\d*\.)?\d+$/)]],
    //     LoanStartDate: ['', [Validators.required]],
    //     LoanEndDate: [''],
    //     PaymentMonth: [''],
    //     PaymentYear: [''],
    //     OutstnadingPrincipal: [''],
    //     OutstandingInterest: [''],
    //     TotalInterest: [''],
    //     LoanStatus: ['true'],
    //     CreatedBy: [this.cs.userSession.Id]

    //   })
    // })
  }

  ngOnInit() {

    this.BindUserLoan()

  }




  BindUserLoan() {
    this.cs.userloan().subscribe((res: any) => {
      // this.loandetails = res.ReturnObject;
      for (let i = 0; i < res.ReturnObject.length; i++) {

        if (res.ReturnObject[i].UserId == this.cs.userSession.Id) {
          this.loandetails[0] = res.ReturnObject[i];
          if (this.loandetails[0].Approvalstatus == null) {
            this.loandetails[0].Approvalstatus = "Pending";
          } else if (this.loandetails[0].Approvalstatus == true) {
            this.loandetails[0].Approvalstatus = "Approval";
          } else {
            this.loandetails[0].Approvalstatus = "Reject";
          }
          this.loandetails[0]['FirstName'] = this.loandetails[0].MstUser.FirstName;
          this.loandetails[0]['LastName'] = this.loandetails[0].MstUser.LastName;

          if (this.loandetails[0].LoanStatus = true) {
            this.loandetails[0].LoanStatus = "Active"
          } else {
            this.loandetails[0].LoanStatus = "Paid"
          }
        }

      }
      // for (let i = 0; i < this.loandetails.length; i++) {
      //   this.loandetails[i]['FirstName'] = this.loandetails[i].MstUser.FirstName;
      //   this.loandetails[i]['LastName'] = this.loandetails[i].MstUser.LastName;
      // }
      this.dataSource = new MatTableDataSource(this.loandetails);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }

  // patchMatchTable() {
  //   this.dataSource = new MatTableDataSource(this.loandetails[]);
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  addLoan(Lid: number) {
    this.loandetail.Id = Lid;
    this.loandetail.LoanStartDate = new Date();

    const dialogRef = this.dailog.open(LoanapplicationdailogComponent, {
      height: "550px",
      width: "500px",
      data: { value: this.loandetail }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.BindUserLoan();
    });

  }

  editLoan(row: any) {

    const dialogRef = this.dailog.open(LoanapplicationdailogComponent, {
      height: "500px",
      width: "500px",
      data: { value: row }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.BindUserLoan();
    });

  }

  // edit(row:Loandetail){
  //   this.state = "edit"
  //   this.fg = this.fb.group({
  //     UserId: [this.cs.userSession.Id],
  //     GroupID: [this.cs.userSession.GroupID],
  //     InterestRate: [row.InterestRate],
  //     Tenure: [row.Tenure, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
  //     Principal: [row.Principal, [Validators.required, Validators.pattern(/^(\d*\.)?\d+$/)]],
  //     LoanStartDate: [row.LoanStartDate, [Validators.required]],
  //     LoanEndDate: [row.LoanEndDate],
  //     // LoanStatus: [''],
  //     OutstnadingPrincipal: [row.OutstnadingPrincipal],
  //     OutstandingInterest: [row.OutstandingInterest],
  //     TotalInterest: [row.TotalInterest],
  //     LoanStatus: ['true'],


  //   })
  // }

  // apply(){
  //   this.fg.patchValue({

  //     Tenure: '',
  //     Principal: '',
  //     LoanStartDate: '',
  //     LoanEndDate: '',

  //     OutstnadingPrincipal: '',
  //     OutstandingInterest: '',
  //     TotalInterest: '',



  //   })
  //   this.state = "form";
  // }

  // onchangePrincipalInterest() {
  //   var obj = this.fg.value;
  //   var calulatedInterest;
  //   obj.Principal = obj.principal == "" ? 0 : obj.Principal;
  //   obj.InterestRate =  this.interestrate;
  //   calulatedInterest = obj.Principal * (obj.InterestRate / 100);
  //   this.datavalue = calulatedInterest;
  //   this.pdata = obj.Principal;
  //   this.idata = calulatedInterest;
  //   this.fg.patchValue({
  //     OutstnadingPrincipal:this.pdata,
  //     OutstandingInterest:this.idata,
  //     TotalInterest:this.datavalue
  //   })
  // }

  // datechangeEvent(event) {
  //   var date = moment(this.fg.value.LoanStartDate);
  //   var selectedTenureDate = moment(date.add(this.fg.value.Tenure,'months'));
  //   this.fg.patchValue({
  //     LoanEndDate : selectedTenureDate.toDate()
  //   })
  //   // this.fg.value.LoanEndDate = selectedTenureDate.toDate();
  //   var date1 = new Date();
  // }

  // applyloan(){
  //   this.fg.value.TotalInterest = this.datavalue;
  //   this.fg.value.OutstandingInterest = this.idata;
  //   this.fg.value.OutstnadingPrincipal = this.pdata;
  //     var obj = this.fg.value;
  //     this.cs.userLoanAdd(obj).subscribe((res: any) => {
  //       this.state = "default";
  //     });

  // }

  // updateloan(){
  //     var obj = this.fg.value;
  //     this.cs.userLoanEdit(obj).subscribe((res: any) => {
  //       this.state = "default";
  //     });
  // }

  // cancel(){
  //  this.state = "default";
  //  this.fg.patchValue({

  //   Tenure: '',
  //   Principal: '',
  //   LoanStartDate: '',
  //   LoanEndDate: '',

  //   OutstnadingPrincipal: '',
  //   OutstandingInterest: '',
  //   TotalInterest: '',



  // })
  // }
}
