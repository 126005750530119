import { Group } from './group';

export class User {
    Id?: number;
    RoleID: number;
    GroupID: number;
    DOB: Date;
    FirstName: string;
    LastName: string;
    Email: string;
    Gender: string;
    CellNumber: string;
    Active: boolean;
    Password: string;
    CreatedBy: number;
    ModifiedBy: number;
    Roles: any;
    Groups: Group;
    InterestType: string;
    SettingStartDate: Date;
    SettingEndDate: Date;
    SettingInterestRate: number;
    SettingLoanEligibility: number;
    SettingSharePrice: number;
}