import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User } from 'src/app/models/user';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { Role } from 'src/app/models/roles';
import { error_messege } from 'src/app/models/error_massege'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/modules/custom-validators';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {
  user: User = null;
  users: User[] = [];


  error_messege = error_messege;
  action: string;
  userdata;
  state: string = "add";
  role: Role;
  roles: Role[] = [];

  fg: FormGroup;
  maxNewDate = new Date();

  genders: string[] = ['Male', 'Female'];

  constructor(public dialogRef: MatDialogRef<AdduserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private cs: CommonService, private ngxloader: NgxUiLoaderService, private route: Router) {
    dialogRef.disableClose = true;
    this.maxNewDate.setUTCFullYear(this.maxNewDate.getUTCFullYear() - 5);
    this.cs.fillSession();
    this.fg = this.fb.group({
      Id: [],
      RoleID: [],
      GroupId: [this.cs.userSession.GroupID],
      FirstName: ['', [Validators.required, Validators.pattern('[A-Za-z ]*')]],
      LastName: ['', [Validators.required, Validators.pattern('[A-Za-z ]*')]],
      Email: ['', [Validators.compose([Validators.required, Validators.email, CustomValidators.trimValidator])]],
      Gender: [''],
      DOB: [''],
      CellNumber: ['', [Validators.required]],
      Active: [''],
      // [(control) => {
      //   return !control.value ? { 'required': true } : null;
      // }]],
      // Password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#$!^%*+&])[A-Za-z\d$@#$!^%*+&].{8,}')]]
      Password: ['', Validators.compose([
        Validators.required,
        // check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true
        }),
        // check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        // check whether the entered password has a lower case letter
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        // check whether the entered password has a special character
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          // /[ !@#$%^&*()_+-]/,
          {
            hasSpecialCharacters: true
          }
        ),
        Validators.minLength(8)
      ])],
    });

    this.fg.controls.Email.valueChanges.subscribe((val) => {
      this.fg.patchValue(
        {
          Email: val.trim()
        },
        { emitEvent: false })
    });
  }

  get f() {
    return this.fg;
  }

  ngOnInit() {
    var gid = this.cs.userSession.GroupID;
    this.cs.roleslist(gid).subscribe((res: any) => {
      this.roles = res.ReturnObject;

    })
    this.user = this.data.value;

    if (this.user.Id == 0)
      this.state = "add";
    else {
      this.state = "edit";
      // this.fg = this.fb.group({
      //   RoleID: [this.user.RoleID],
      //   GroupId: [this.cs.userSession.GroupID],
      //   FirstName: [this.user.FirstName, [Validators.required, Validators.pattern('[A-Za-z ]*')]],
      //   LastName: [this.user.LastName, [Validators.required, Validators.pattern('[A-Za-z ]*')]],
      //   Email: [this.user.Email, [Validators.required, Validators.email]],
      //   Gender: [this.user.Gender.trim()],
      //   CellNumber: [this.user.CellNumber.trim(), [Validators.required, Validators.maxLength(10), Validators.minLength(10)]],
      //   Active: [this.user.Active],
      //   DOB: [this.user.DOB],
      //   ModifiedBy: [this.cs.userSession.Id],
      //   Password: [this.user.Password, Validators.pattern('[a-zA-Z0-9!@#$%^&*()]+$')]

      // })
      this.fg.patchValue({
        RoleID: this.user.RoleID,
        GroupId: this.cs.userSession.GroupID,
        FirstName: this.user.FirstName,
        LastName: this.user.LastName,
        Email: this.user.Email,
        Gender: this.user.Gender.trim(),
        CellNumber: this.user.CellNumber.trim(),
        Active: this.user.Active,
        DOB: this.user.DOB,
        ModifiedBy: this.cs.userSession.Id,
        Password: this.user.Password
      });

    }
  }





  onNoClick() {

    var user: User = this.fg.value;

    user.CreatedBy = this.cs.userSession.Id;
    user.ModifiedBy = this.cs.userSession.Id;
    if (this.fg.valid) {
      //Submit form logic here!
    } else {
      this.cs.showSnackBar('success', 'Please fill all fields');
    }
    if (this.state == "add") {

      this.cs.useradd(user).subscribe((res: any) => {
        this.ngxloader.start();
        if (res.IsError == 1) {
          this.cs.showSnackBar('danger', res.DetailsError);
          this.ngxloader.stop();
        } else {
          this.cs.showSnackBar('success', 'User Record Added successfully')
          this.ngxloader.stop();
        }
        this.dialogRef.close();

      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured : User Record Not Created ');
      })
    } else {
      // var user: User = this.fg.value
      user.Id = this.user.Id;
      this.cs.userupdate(user).subscribe((res: any) => {
        this.ngxloader.start();
        if (res) {
          this.cs.showSnackBar('success', 'User Record Updated successfully');
          this.ngxloader.stop();
        } else {
          this.cs.showSnackBar('danger', 'User Record Not Updated successfully');
          this.ngxloader.stop();
        }
        this.dialogRef.close();

      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured : User Record Not Updated');
      })
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
