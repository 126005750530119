import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loandetailreport',
  templateUrl: './loandetailreport.component.html',
  styleUrls: ['./loandetailreport.component.css']
})
export class LoandetailreportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
