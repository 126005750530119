import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Chart } from 'angular-highcharts';
import Highcharts from 'highcharts';
import { CommonService } from 'src/app/service/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { concat } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  state: string = "";
  cumulative: any[] = [];
  Months: string;
  Amount: number;
  arr: any[] = [];
  str: string = "";
  user: any[] = [];
  userCount: any;
  TotalContribution: any;
  TotalLoanDisburse: any;
  UserShares: any;
  TotalShares: any;
  shares: any;
  @ViewChild('charts', { static: true }) public chartEl: ElementRef;
  @ViewChild('charts1', { static: false }) public chartE1: ElementRef;
  @ViewChild('charts2', { static: true }) public chartE2: ElementRef;
  @ViewChild('charts3', { static: false }) public chartE3: ElementRef;
  @ViewChild('charts4', { static: false }) public chartE4: ElementRef;
  @ViewChild('charts5', { static: false }) public chartE5: ElementRef;


  constructor(private cs: CommonService, private ngxloader: NgxUiLoaderService) {
    this.cs.fillSession();
  }

  ngOnInit() {

    if (this.cs.userSession.Roles.RoleName == "Group User") {
      this.usercumulative();
      this.userdashboardcard();
      this.Sharepiechart();
      this.state = "user";
    }
    else if (this.cs.userSession.Roles.RoleName == "Group Admin") {
      this.cumulativeall();
      this.piechart();
      this.admindashboardcard();
      this.Sharepiechart();
      this.state = "admin";
    }
    this.cs.userreport().subscribe((res: any) => {
      this.user = res;
    })
  }


  admindashboardcard() {
    this.cs.dashboardCards().subscribe((users: any) => {

      this.userCount = users.ReturnObject[0].UserCount;
      this.TotalContribution = users.ReturnObject[0].TotalContribution;
      this.TotalLoanDisburse = users.ReturnObject[0].TotalLoanDisburse;
      this.TotalLoanDisburse = users.ReturnObject[0].TotalLoanDisburse;
      this.TotalLoanDisburse = users.ReturnObject[0].TotalLoanDisburse;
      this.UserShares = users.ReturnObject[0].UserShares;
      this.TotalShares = users.ReturnObject[0].TotalShares;
      this.shares = +this.UserShares + '/' + this.TotalShares;
    }, error => {
      this.cs.showSnackBar('danger', 'No data found');
    });
  }

  userdashboardcard() {
    this.cs.userdashboardCards().subscribe((users: any) => {
      this.ngxloader.start();
      this.userCount = users.ReturnObject[0].UserCount;
      this.TotalContribution = users.ReturnObject[0].TotalContribution;
      this.TotalLoanDisburse = users.ReturnObject[0].TotalLoanDisburse;
      this.UserShares = users.ReturnObject[0].UserShares;
      this.TotalShares = users.ReturnObject[0].TotalShares;
      this.shares = this.UserShares;
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'No data found');
    });
  }
  // exportAsXLSX(): void {

  //   this.cs.exportAsExcelFile(this.arr, 'sample');

  // }

  cumulativeall() {

    this.cs.cumulative().subscribe((res: any) => {
      this.ngxloader.start();
      this.cumulative = res;
      // for (var i = 0; i < res.split(',[').length; i++) {
      //   // var x = JSON.parse(res.split(',[')[i]);
      //   this.arr.push(JSON.parse(res.split(',[')[i]));
      // }
      var arr = [];

      for (var i = 0; i < res.split(',[').length; i++) {
        var x = res.split(',[')[i];
        var y = x.split(',');
        var arr1 = [];
        if (y == "") {
          this.cs.showSnackBar('danger', 'data is not found');
          this.ngxloader.stop();
        } else {
          arr1.push(y[0].replace('[', '').replace(/'/g, ''));
          arr1.push(JSON.parse(y[1].replace(']', '')));

          // arr.push((res.split(',[')[i]));
          arr.push(arr1);
          if (this.str === '')
            this.str = JSON.stringify(arr1);
          else
            this.str = this.str + ',' + JSON.stringify(arr1);
          this.arr = arr;
          this.ngxloader.stop();
        }
      }
      //bindYearlyData(JSON.parse(data.split(',[')[0]));
      var drilldowncharts = {
        chart: {
          type: 'column'
        },
        title: {
          text: 'Monthly Contribution'
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          type: 'category',
          labels: {
            rotation: -45,
            style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: ''
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          // pointFormat: ': <b>{point.y:.1f } </b>'
          pointFormat: ': <b>{point.y:.1f } </b>',
          pointFormatter: function () {
            let result = 0;
            for (let i = this.series.index; i >= 0; i--) {
              result += this.series.chart.series[i].data[this.index].y;
            }
            return ':' + this.y;
          }
        },
        series: [{
          name: 'Population',
          data: arr,
          dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.1f}', // one decimal
            y: 10, // 10 pixels down from the top
            style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
            }
          }
        }]

      }

      this.cs.createChart(this.chartE3.nativeElement, drilldowncharts);
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'No data found');
    })
  }

  usercumulative() {

    this.cs.usercumulative().subscribe((res: any) => {
      this.ngxloader.start();

      var arr = [];

      for (var i = 0; i < res.split(',[').length; i++) {
        var x = res.split(',[')[i];
        var y = x.split(',');
        var arr1 = [];
        if (y == "") {
          this.cs.showSnackBar('danger', 'data is not found');
          this.ngxloader.stop();
        } else {
          arr1.push(y[0].replace('[', '').replace(/'/g, ''));
          arr1.push(JSON.parse(y[1].replace(']', '')));
          // arr.push((res.split(',[')[i]));
          arr.push(arr1);
          if (this.str === '')
            this.str = JSON.stringify(arr1);
          else
            this.str = this.str + ',' + JSON.stringify(arr1);
          this.arr = arr;
          this.ngxloader.stop();
        }
      }
      var drilldowncharts1 = {
        chart: {
          type: 'column'
        },
        title: {
          text: 'Monthly Contribution'
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          type: 'category',
          labels: {
            rotation: -45,
            style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: ''
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          // pointFormat: 'Population in 2017: <b>{point.y:.1f} millions</b>'
          pointFormat: ': <b>{point.y:.1f } </b>',
          pointFormatter: function () {
            let result = 0;
            for (let i = this.series.index; i >= 0; i--) {
              result += this.series.chart.series[i].data[this.index].y;
            }
            return ':' + this.y;
          }
        },
        series: [{
          name: 'Population',
          data: arr,
          dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.1f}', // one decimal
            y: 10, // 10 pixels down from the top
            style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
            }
          }
        }]

      }

      this.cs.createChart(this.chartE1.nativeElement, drilldowncharts1);
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'No data found');
    })

  }

  piechart() {

    this.cs.pie().subscribe((res: any) => {

      var arr = [];

      for (let i = 0; i < res.split(',[').length; i++) {
        let x = res.split(',[')[i];
        let y = x.split(',');
        let arr1 = [];
        if (y == "") {
          this.cs.showSnackBar('danger', 'data is not found');
          this.ngxloader.stop();
        } else {
          arr1.push(y[0].replace('[', '').replace(/'/g, ''));
          arr1.push(JSON.parse(y[1].replace(']', '')));
          arr.push(arr1);
          if (this.str === '')
            this.str = JSON.stringify(arr1);
          else
            this.str = this.str + ',' + JSON.stringify(arr1);
          // this.arr = arr;
          this.ngxloader.stop();
        }
      }

      if (arr.length > 0) {
        var PayoutpieChartOptions = {
          chart: {
            // renderTo: 'container',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false
          },
          credits: {
            enabled: false
          },
          title: {
            text: 'Contribution Chart'
          },
          subtitle: {
            text: ''
          },
          tooltip: {
            pointFormat: '<b>{point.percentage:.2f}%</b> <br><b>{point.y:.2f}</b>',
            valueDecimals: 2
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false,
                color: '#000000',
                connectorColor: '#000000',
                formatter: function () {
                  return '<b>' + this.point.name + '</b>: ' + Highcharts.numberFormat(this.percentage, 2) + ' %';
                }
              },
              showInLegend: true
            }
          },
          series: [{
            type: 'pie',
            name: '',
            data: arr
          }]

        };

        // var AmountpieChartOptions = {
        //   chart: {
        //     // renderTo: 'container',
        //     plotBackgroundColor: null,
        //     plotBorderWidth: null,
        //     plotShadow: false
        //   },
        //   credits: {
        //     enabled: false
        //   },
        //   title: {
        //     text: 'Amount Charts'
        //   },
        //   subtitle: {
        //     text: ''
        //   },
        //   tooltip: {
        //     // pointFormat: '<b>{point.percentage}%</b>',
        //     // percentageDecimals: 1
        //     pointFormat: '<b>{point.y:.2f}</b>',
        //     valueDecimals: 2
        //   },
        //   plotOptions: {
        //     pie: {
        //       allowPointSelect: true,
        //       cursor: 'pointer',
        //       dataLabels: {
        //         enabled: false,
        //         color: '#000000',
        //         connectorColor: '#000000',
        //         formatter: function () {
        //           return '<b>' + this.point.name + '</b>: ' + Highcharts.numberFormat(this.y,2) +',' + Highcharts.numberFormat(this.percentage, 2) + ' %';
        //         }
        //       },
        //       showInLegend: true
        //     }
        //   },
        //   series: [{
        //     type: 'pie',
        //     name: '',
        //     data: arr
        //   }]

        // };
        // this.cs.createChart(this.chartE4.nativeElement, AmountpieChartOptions);


        this.cs.createChart(this.chartE2.nativeElement, PayoutpieChartOptions);
      }


    })
  }

  Sharepiechart() {

    this.cs.GetShareUsersreport().subscribe((res: any) => {

      var arr = [];

      for (let i = 0; i < res.split(',[').length; i++) {
        let x = res.split(',[')[i];
        let y = x.split(',');
        let arr1 = [];
        if (y == "") {
          this.cs.showSnackBar('danger', 'data is not found');
          this.ngxloader.stop();
        } else {
          arr1.push(y[0].replace('[', '').replace(/'/g, ''));
          arr1.push(JSON.parse(y[1].replace(']', '')));
          arr.push(arr1);
          if (this.str === '')
            this.str = JSON.stringify(arr1);
          else
            this.str = this.str + ',' + JSON.stringify(arr1);
          // this.arr = arr;
          this.ngxloader.stop();
        }

      }

      if (arr.length > 0) {
        var PayoutpieChartOptions = {
          chart: {
            // renderTo: 'container',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false
          },
          credits: {
            enabled: false
          },
          title: {
            text: 'Share Chart'
          },
          subtitle: {
            text: ''
          },
          tooltip: {
            pointFormat: '<b>{point.percentage:.2f}%</b> <br><b>{point.y:.2f}</b>',
            valueDecimals: 2
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false,
                color: '#000000',
                connectorColor: '#000000',
                formatter: function () {
                  return '<b>' + this.point.name + '</b>: ' + Highcharts.numberFormat(this.percentage, 2) + ' %';
                }
              },
              showInLegend: true
            }
          },
          series: [{
            type: 'pie',
            name: '',
            data: arr
          }]

        };
        this.cs.createChart(this.chartE5.nativeElement, PayoutpieChartOptions);
      }


    })
  }

  // piechart() {

  //   this.cs.pie().subscribe((res: any) => {
  //     this.ngxloader.start();

  //     var arr = [];

  //     for (var i = 0; i < res.split(',[').length; i++) {
  //       var x = res.split(',[')[i];
  //       var y = x.split(',');
  //       var arr1 = [];
  //       arr1.push(y[0].replace('[', '').replace(/'/g, ''));
  //       arr1.push(JSON.parse(y[1].replace(']', '')));
  //       // arr.push((res.split(',[')[i]));
  //       arr.push(arr1);
  //       if (this.str === '')
  //         this.str = JSON.stringify(arr1);
  //       else
  //         this.str = this.str + ',' + JSON.stringify(arr1);
  //       this.arr = arr;
  //     }

  //     var payout = {
  //       chart: {
  //         // renderTo: 'container',
  //         plotBackgroundColor: null,
  //         plotBorderWidth: null,
  //         plotShadow: false
  //       },
  //       credits: {
  //         enabled: false
  //       },
  //       title: {
  //         text: 'Payout Charts'
  //       },
  //       subtitle: {
  //         text: ''
  //       },
  //       tooltip: {
  //         pointFormat: '<b>{point.percentage:.2f}%</b>',
  //         valueDecimals: 2
  //       },
  //       plotOptions: {
  //         pie: {
  //           allowPointSelect: true,
  //           cursor: 'pointer',
  //           dataLabels: {
  //             enabled: true,
  //             color: '#000000',
  //             connectorColor: '#000000',
  //             formatter: function () {
  //               return '<b>' + this.point.name + '</b>: ' + Highcharts.numberFormat(this.percentage, 2) + ' %';
  //             }
  //           }
  //         }
  //       },
  //       series: [{
  //         type: 'pie',
  //         name: '',
  //         data: arr
  //       }]

  //     };

  //     var amount = {
  //       chart: {
  //         // renderTo: 'container',
  //         plotBackgroundColor: null,
  //         plotBorderWidth: null,
  //         plotShadow: false
  //       },
  //       credits: {
  //         enabled: false
  //       },
  //       title: {
  //         text: 'Amount Charts'
  //       },
  //       subtitle: {
  //         text: ''
  //       },
  //       tooltip: {
  //         // pointFormat: '<b>{point.percentage}%</b>',
  //         // percentageDecimals: 1
  //         // pointFormat: '<b>{point.y:.2f }</b>',
  //         valueDecimals: 2
  //       },
  //       plotOptions: {
  //         pie: {
  //           allowPointSelect: true,
  //           cursor: 'pointer',
  //           dataLabels: {
  //             enabled: true,
  //             color: '#000000',
  //             connectorColor: '#000000',
  //             formatter: function () {
  //               return '<b>' + this.point.name + '</b>: ' + Highcharts.numberFormat(this.y, 2) ;
  //             }
  //           }
  //         }
  //       },
  //       series: [{
  //         type: 'pie',
  //         name: '',
  //         data: arr
  //       }]

  //     };

  //     this.cs.createChart(this.chartE4.nativeElement, amount);

  //     this.cs.createChart(this.chartE2.nativeElement, payout);
  //     this.ngxloader.stop();
  //   }, error => {
  //     this.ngxloader.stop();
  //     this.cs.showSnackBar('danger', 'No data found');
  //   })
  // }





}
