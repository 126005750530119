export var error_messege = {


    'FirstName': [
        { type: 'required', message: 'Name Is Required' },
        { type: 'pattern', message: 'Only character is required' }
    ],
    'RoleName': [
        { type: 'required', message: 'Name Is Required' },
        { type: 'pattern', message: 'Only character is required' }
    ],
    'LastName': [
        { type: 'required', message: 'Name Is Required' },
        { type: 'pattern', message: 'Only character is required' }
    ],
    'Email': [
        { type: 'required', message: 'Email Is Required' },
        { type: 'email', message: 'Invalid Email' }
    ],
    'CellNumber': [
        { type: 'required', message: 'Mobile Number is required' },
        { type: 'pattern', message: 'Only Numeric value Is Required' },
        { type: 'minlength', message: 'Invalid Mobile Number' },
        { type: 'maxlength', message: 'Invalid Mobile Number' },
    ],
    'UserContribution1': [
        { type: 'required', message: 'amount is required' },
        { type: 'pattern', message: 'Only numeric value is required' }
    ],
    'Tenure': [
        { type: 'required', message: 'Tenure is required' },
        { type: 'pattern', message: 'Only numeric value is required' }
    ],
    'Principal': [
        { type: 'required', message: 'amount is required' },
        { type: 'pattern', message: 'Only numeric value is required' }
    ],
    'LoanRepay': [
        { type: 'required', message: 'amount is required' },
        { type: 'pattern', message: 'Only numeric value is required' }
    ],
    'repaymentTemp': [
        { type: 'required', message: 'amount is required' },
        { type: 'pattern', message: 'Only numeric value is required' }
    ],
    'Interest': [
        { type: 'required', message: 'amount is required' },
        { type: 'pattern', message: 'Only numeric value is required' }
    ],
    'InterestRate': [
        { type: 'required', message: 'amount is required' },
        { type: 'pattern', message: 'Only numeric value is required' }
    ],
    'OutstnadingPrincipal': [
        { type: 'required', message: 'amount is required' },
        { type: 'pattern', message: 'Only numeric value is required' }
    ],
    'OutstandingInterest': [
        { type: 'required', message: 'amount is required' },
        { type: 'pattern', message: 'Only numeric value is required' }
    ],
    'TotalInterest': [
        { type: 'required', message: 'amount is required' },
        { type: 'pattern', message: 'Only numeric value is required' }
    ],
    'uName': [
        { type: 'required', message: 'User Name is required' },
        { type: 'email', message: 'Invalid User Name' }
    ],
    'password': [
        { type: 'required', message: 'Password is required' },
        { type: 'minlength', message: 'Password must be at least 6 chars long' },
        { type: 'pattern', message: 'Password must be combination of A-Z a-z 0-9 ! @ # $ % ^ & * ( ) ] + $' }
    ],

    'Password': [
        { type: 'required', message: 'Password is required' },
        { type: 'minlength', message: 'Password must be at least 6 chars long' },
        { type: 'pattern', message: 'Password must be combination of A-Z a-z 0-9 ! @ # $ % ^ & * ( ) ] + $' }
    ],

    'confirm_password': [
        { type: 'required', message: 'Confirm password is required' },
        { type: 'mustMatch', message: 'Passwords must be matched' }
    ],
    'old_password': [
        { type: 'required', message: 'Old password is required' },
        { type: 'check', message: 'Old password is wrong' }
    ],

    'OTPCode': [
        { type: 'required', message: 'Activation code is required' },

    ]
}