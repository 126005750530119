import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { Group } from 'src/app/models/group';

@Component({
  selector: 'app-groupdailog',
  templateUrl: './groupdailog.component.html',
  styleUrls: ['./groupdailog.component.css']
})
export class GroupdailogComponent implements OnInit {
  group: Group = null;
  groups: Group[] = [];
  state: string = "";
  fg: FormGroup;

  genders: string[] = ['Male', 'Female'];

  constructor(public dialogRef: MatDialogRef<GroupdailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private cs: CommonService, private ngxloader: NgxUiLoaderService, private route: Router) {
    dialogRef.disableClose = true;
    this.cs.fillSession();
    this.fg = this.fb.group({
      GroupName: ['', [Validators.required, Validators.pattern('[A-Za-z ]*')]],
      GroupMembers: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      Active: [''],
    })
  }

  get f() {
    return this.fg;
  }

  ngOnInit() {
    this.group = this.data.value;
    if (this.group.GroupId == 0)
      this.state = "add";
    else {
      this.state = "edit";
      // this.fg = this.fb.group({
      //   GroupId: [this.group.GroupId],
      //   GroupName: [this.group.GroupName, [Validators.required]],
      //   GroupMembers: [this.group.GroupMembers, [Validators.required]],
      //   Active: [''],
      // })
      this.fg.patchValue({
        GroupId: this.group.GroupId,
        GroupName: this.group.GroupName,
        GroupMembers: this.group.GroupMembers,
        Active: this.group.Active
      });
    }
  }

  onNoClick() {
    var group: Group = this.fg.value;
    if (this.fg.valid) {
      //Submit form logic here!
    } else {
      this.cs.showSnackBar('success', 'Please fill all fields');
    }
    if (this.state == "add") {
      this.cs.addGroups(group).subscribe((res: any) => {
        this.ngxloader.start();
        this.cs.showSnackBar('success', 'User Record Added successfully')
        this.ngxloader.stop();
        this.dialogRef.close();
      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured : User Record Not Created ');
      })
    } else {
      // var user: User = this.fg.value
      group.GroupId = this.group.GroupId;
      this.cs.updateGroups(group).subscribe((res: any) => {
        this.ngxloader.start();
        if (res) {
          this.cs.showSnackBar('success', 'User Record Updated successfully');
        } else {
          this.cs.showSnackBar('danger', 'User Record Not Updated successfully');
        }
        this.dialogRef.close();
        this.ngxloader.stop();
      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured : User Record Not Updated');
      })
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
