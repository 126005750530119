import { Data } from '@angular/router';

export class Usercontribution {
    Id?: number;
    LoanId?: number;
    GroupId: number;
    UserId: number;
    UserContribution1: number;
    Paymentdate: Date;
    PaymentMonth: Date;
    PaymentYear: Date;
    ContributionType: string;
    ContributionStatus?: boolean;
    MstUser: any;
    MonthNo: any;
    CumulativeSavings?: any;
    InterestOnSavings?: any;
    CumulativeWithInterest?: any;
    Penalty?: any;
}