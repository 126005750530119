import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule, MatButtonModule, MatChipsModule, MatCardModule, MatInputModule, MatFormFieldModule, MatSidenavModule, MatListModule, MatIconModule, MatExpansionModule, MatDatepickerModule, MatSelectModule, MatTableModule, MatPaginatorModule, MatSortModule, MatDialogModule, MatGridListModule, MatRadioModule, MatSlideToggleModule, MatSnackBarModule, MatNativeDateModule, MatMenuModule, MatTabsModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatListModule,
    FlexLayoutModule,
    MatIconModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatGridListModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatMenuModule,
    MatChipsModule,
    MatTabsModule

  ],
  exports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatListModule,
    FlexLayoutModule,
    MatIconModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatGridListModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatMenuModule,
    MatChipsModule,
    MatTabsModule


  ]
})
export class MaterialModule { }
