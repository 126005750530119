export class Loandetail {
    Id?: number;
    UserId: number;
    GroupID: number;
    Tenure: number;
    InterestRate: number;
    Principal: number;
    LoanStartDate: Date;
    LoanEndDate: Date;
    PaymentMonth: number;
    PaymentYear: number;
    LoanStatus: any;
    Approvalstatus: any;
    OutstnadingPrincipal: number;
    OutstandingInterest: number;
    TotalInterest: number;
    MstUser: any;
    Comments: string;
    CreatedBy: number;
    ModifiedBy: number;
    // Approvalstatus
}