import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {


  opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  router: string;
  watcher: Subscription;
  FirstName: string;
  LastName: string;

  constructor(private route: Router, media: MediaObserver, private ngxloader: NgxUiLoaderService, public cs: CommonService) {
    this.cs.fillSession();
    this.watcher = media.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
        this.opened = false;
        this.over = 'over';
      } else {
        this.opened = true;
        this.over = 'side';
      }
    });
  }
  ngOnInit() {
    // if(this.cs.userSession.RoleID == 3){}

  }

  logout() {
    localStorage.clear();

    this.route.navigate(['adminlogin']);

  }

}
