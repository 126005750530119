import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { Role } from 'src/app/models/roles';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { error_messege } from 'src/app/models/error_massege'

@Component({
  selector: 'app-rolesdailog',
  templateUrl: './rolesdailog.component.html',
  styleUrls: ['./rolesdailog.component.css']
})
export class RolesdailogComponent implements OnInit {
  fg: FormGroup;
  error_messege = error_messege;
  role: Role = new Role();
  roles: Role[] = [];
  state: string = "add";
  constructor(public dialogRef: MatDialogRef<RolesdailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private cs: CommonService, private ngxloader: NgxUiLoaderService) {
    this.cs.fillSession();
    this.fg = this.fb.group({
      GroupID: [this.cs.userSession.GroupID],
      RoleName: ['', [Validators.required, Validators.pattern('[A-Za-z ]*')]],
      Active: ['']
    })
  }

  ngOnInit() {

    this.role = this.data.value;
    if (this.role.Id == 0)
      this.state = "add";
    else {
      this.state = "edit";
      this.fg = this.fb.group({
        GroupID: [this.cs.userSession.GroupID],
        RoleName: [this.role.RoleName, [Validators.required, Validators.pattern('[A-Za-z ]*')]],
        Active: [this.role.Active]
      })
    }
  }

  get f() {
    return this.fg;
  }

  onclick() {
    var role: Role = this.fg.value;
    
    if (this.state == "add") {
      this.cs.roleregister(role).subscribe((res: any) => {
        this.ngxloader.start();
        this.ngxloader.stop();
      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured');
      })
    } else {
      role.Id = this.role.Id;
      this.cs.roleupdate(role).subscribe((res: any) => {
        this.ngxloader.start();
        this.ngxloader.stop();
      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured ');
      })
    }
  
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

}
