import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { Setting } from 'src/app/models/setting';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Usercontribution } from 'src/app/models/usercontribution';
import { Loandetail } from 'src/app/models/loandetails';
import * as moment from 'moment';


@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {

  settings: Setting[] = [];
  usercontributions: Usercontribution[] = [];
  loandetails: Loandetail[] = [];
  fg: FormGroup;
  state: string = "default";
  minDate: Date;
  paymentyear: any[];
  PaymentMonth: any[];
  startmonth: any;
  startyear: any;
  endmonth: any;
  endyear: any;
  startmonthName: any;
  endmonthName: any;
  displayedColumns: string[] = ['Id', 'InterestRate', 'LoanEligibility', 'StartMonth', 'StartYear', 'EndMonth', 'EndYear', 'StartDate', 'EndDate', 'SharePrice', 'LoanType', 'action'];
  dataSource: MatTableDataSource<Setting>;
  constructor(private cs: CommonService, private fb: FormBuilder, private route: Router, private ngxloader: NgxUiLoaderService) {
    this.cs.fillSession();
    this.minDate = new Date();
    this.paymentyear = CommonService.PaymentYear;
    this.PaymentMonth = CommonService.PaymentMonth;
    this.fg = this.fb.group({
      Id: [''],
      GroupId: [this.cs.userSession.GroupId],
      InterestRate: [''],
      StartMonth: [''],
      StartYear: [''],
      EndMonth: [''],
      EndYear: [''],
      StartDate: [''],
      EndDate: [''],
      SharePrice: [''],
      LoanEligibility: [''],
      LoanType: ['']
    })

  }

  ngOnInit() {

    // this.cs.contributionlist().subscribe((res: any) => {
    //   this.usercontributions = res.ReturnObject;
    //   this.cs.userloan().subscribe((res: any) => {
    //     this.loandetails = res.ReturnObject;
    //     if (this.usercontributions.length != null && this.loandetails.length != null) {
    //       this.fg = this.fb.group({
    //         LoanType: [this.settings[0].LoanType]
    //       })
    //       this.state = "hide"
    //     }
    //   })
    // });
    this.bindsetting();

  }

  bindsetting() {
    this.cs.getSetting().subscribe((res: any) => {
      this.settings = res.ReturnObject;
      var sm = this.settings[0].StartMonth;
      var em = this.settings[0].EndMonth;
      this.startmonthName = sm;// CommonService.PaymentMonth.filter(x => x.value == sm)[0];
      this.endmonthName = em;//CommonService.PaymentMonth.filter(x => x.value == em)[0];
      this.patchMatchTable();
    });
  }
  patchMatchTable() {
    this.dataSource = new MatTableDataSource(this.settings);
  }


  edit(row: Setting) {

    this.cs.getSetting().subscribe((res: any) => {
      this.ngxloader.start();
      this.settings = res.ReturnObject;
      let startdate = new Date(this.settings[0].StartDate)
      let Enddate = new Date(this.settings[0].EndDate)
      // let momentDate = moment(date1);
      this.startmonth = (startdate.getMonth() + 1);
      this.startyear = startdate.getFullYear();

      this.endmonth = (Enddate.getMonth() + 1);
      this.endyear = Enddate.getFullYear();

      this.cs.contributionlist().subscribe((res: any) => {
        this.usercontributions = res.ReturnObject;
        this.cs.userloan().subscribe((res: any) => {
          this.loandetails = res.ReturnObject;
          if (this.usercontributions.length != null && this.loandetails.length != null) {

            this.state = "hide"
            this.fg = this.fb.group({

              Id: [this.settings[0].Id],
              GroupId: [this.settings[0].GroupId],
              InterestRate: [this.settings[0].InterestRate],
              StartMonth: [this.startmonth],
              StartYear: [this.startyear],
              EndMonth: [this.endmonth],
              EndYear: [this.endyear],
              StartDate: [this.settings[0].StartDate],
              EndDate: [this.settings[0].EndDate],
              SharePrice: [this.settings[0].SharePrice],
              LoanEligibility: [this.settings[0].LoanEligibility],
              LoanType: [this.settings[0].LoanType]
            })

          } else {
            this.state = "edit";
            this.fg = this.fb.group({
              Id: [this.settings[0].Id],
              GroupId: [this.settings[0].GroupId],
              InterestRate: [this.settings[0].InterestRate],
              StartMonth: [this.startmonth],
              StartYear: [this.startyear],
              EndMonth: [this.endmonth],
              EndYear: [this.endyear],
              StartDate: [this.settings[0].StartDate],
              EndDate: [this.settings[0].EndDate],
              SharePrice: [this.settings[0].SharePrice],
              LoanEligibility: [this.settings[0].LoanEligibility],
              LoanType: [this.settings[0].LoanType]
            })
            // this.fg.patchValue({
            //   Id: this.settings[0].Id,
            //   GroupId: this.settings[0].GroupId,
            //   InterestRate: this.settings[0].InterestRate,
            //   StartMonth: this.startmonth,
            //   StartYear: this.startyear,
            //   EndMonth: this.endmonth,
            //   EndYear: this.endyear,
            //   StartDate: this.settings[0].StartDate,
            //   EndDate: this.settings[0].EndDate,
            //   SharePrice: this.settings[0].SharePrice,
            //   LoanEligibility: this.settings[0].LoanEligibility,
            //   LoanType: this.settings[0].LoanType
            // })
          }
        })

      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured :  Record Not updated ');
      });
      this.ngxloader.stop();

    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured :  Record Not updated ');
    })

  }

  onchangestartdate(event) {
    this.startmonth = null;
    this.startyear = null
    let startdate = moment(event);
    this.startmonth = startdate.format('MMMM')
    this.startyear = startdate.format('YYYY');
    this.startmonthName = this.startmonth; //CommonService.PaymentMonth.filter(x => x.value == this.startmonth)[0];
    this.fg.patchValue({
      StartMonth: this.startmonth,
      StartYear: this.startyear,
    })


  }
  onchangeenddate(event) {
    this.endmonth = null;
    this.endyear = null
    let Enddate = moment(event)
    this.endmonth = Enddate.format('MMMM');
    this.endyear = Enddate.format('YYYY');
    this.endmonthName = this.endmonth;// CommonService.PaymentMonth.filter(x => x.value == this.endmonth)[0];
    this.fg.patchValue({
      EndMonth: this.endmonth,
      EndYear: this.endyear,
    })

  }

  update() {
    var obj = this.fg.value;
    this.cs.updateInterestrate(obj).subscribe((res: any) => {
      this.ngxloader.start();
      this.cs.showSnackBar('success', 'Record Updated successful');
      this.state = "default";
      this.bindsetting();
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured :  Record Not updated ');
    })
  }

  cancel() {
    this.state = "default";
  }



}
