import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Group } from 'src/app/models/group';
import { GroupdailogComponent } from './groupdailog/groupdailog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {
  group: Group = new Group();
  groups: Group[] = [];
  displayedColumns: string[] = ['GroupId', 'GroupName', 'GroupMembers', 'Active', 'action'];
  dataSource: MatTableDataSource<Group>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(private cs: CommonService, public dailog: MatDialog, private ngxloader: NgxUiLoaderService, private route: Router) {
    if (localStorage.getItem("adminSessionData") == null) {
      this.route.navigate(['login']);
    }
  }

  ngOnInit() {


    this.Bindgroups();
  }

  Bindgroups() {
    this.cs.getGroups().subscribe((res: any) => {
      this.ngxloader.start();
      if (res.ReturnObject == null) {
        this.cs.showSnackBar('danger', 'no data found');
        this.ngxloader.stop();
      } else {
        this.groups = res.ReturnObject;
        this.patchMatchTable();
        this.ngxloader.stop();
      }
      this.ngxloader.stop();

    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }

  patchMatchTable() {
    this.dataSource = new MatTableDataSource(this.groups);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editgroup(row: any) {
    // this.ngxloader.start();
    const dialogRef = this.dailog.open(GroupdailogComponent, {
      width:"500px",
      data: { value: row }
    });
    // this.ngxloader.stop();
    dialogRef.afterClosed().subscribe(result => {
      this.Bindgroups();
    });
  }

  addgroup(groupid: number) {
    this.group.GroupId = groupid;
    const dialogRef = this.dailog.open(GroupdailogComponent, {
      width:"500px",
      data: { value: this.group }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.Bindgroups();
    });
    // this.ngxloader.stop();
  }
}
