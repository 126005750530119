import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { User } from 'src/app/models/user';
import { AdduserComponent } from 'src/app/shared/adduser/adduser.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  value: string;

  state: string = "";
  user: User = new User();
  users: User[] = [];
  displayedColumns: string[] = ['Id', 'AccountNumber', 'FirstName', 'LastName', 'Roles', 'Email', 'Gender', 'CellNumber', 'Active', 'action'];
  dataSource: MatTableDataSource<User>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(private cs: CommonService, public dailog: MatDialog, private ngxloader: NgxUiLoaderService, private route: Router) {
    // if (this.cs.userSession.Roles.RoleName != "Group Admin") {
    //   this.route.navigate(['dashboard']);
    // }
  }



  ngOnInit() {


      
    if (this.cs.userSession.Roles.RoleName == "Group Admin") {
      this.state = "admin";
      this.Bindusers();
      this.dataSource = null;
      this.displayedColumns =  ['Id', 'AccountNumber', 'FirstName', 'LastName', 'Roles', 'Email', 'Gender', 'CellNumber', 'Active', 'action'];
    } else if (this.cs.userSession.Roles.RoleName == "Group User"){
     
      this.state = "user";
      this.Bindusers();
      this.dataSource = null;
      this.displayedColumns =  ['Id', 'AccountNumber', 'FirstName', 'LastName', 'Roles', 'Email', 'Gender', 'CellNumber', 'Active'];
    }

  }

  Bindusers() {

    this.cs.userlist().subscribe((users: any) => {
      this.ngxloader.start();
      this.users = users.ReturnObject;
      this.patchMatchTable();
      this.ngxloader.stop()
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    });

  }

  patchMatchTable() {
  
    this.dataSource = new MatTableDataSource(this.users);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  edituser(row: any) {

    const dialogRef = this.dailog.open(AdduserComponent, {
      height:"600px",
      width:"500px",
      data: { value: row }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.Bindusers();
    });
   
  }

  delete(row : any){
    var obj = row;
    this.cs.GetDeleteUserById(obj.Id).subscribe((res:any)=>{
      if(res == true){
        this.Bindusers();
        this.cs.showSnackBar('success', 'user romove successfully')
      }else{
        this.cs.showSnackBar('danger', 'user can not be deleted');
      }
    })
  }


  adduser(userid: number) {
    this.user.Id = userid;

    const dialogRef = this.dailog.open(AdduserComponent, {
      height:"600px",
      width:"500px",
      data: { value: this.user }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.Bindusers();
    });
  
  }
}
