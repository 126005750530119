import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './modules/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './dashboard/home/home.component';

import { ProductComponent } from './dashboard/product/product.component';
import { DefaultComponent } from './default/default.component';
import { RegisterComponent } from './default/register/register.component';
import { LoginComponent } from './default/login/login.component';
import { MaterialModule } from './modules/material.module';
import { HttpClientModule } from '@angular/common/http';
import { UserComponent } from './dashboard/user/user.component';
import { AdduserComponent } from './shared/adduser/adduser.component';
import { RolesComponent } from './dashboard/roles/roles.component';
import { RolesdailogComponent } from './shared/rolesdailog/rolesdailog.component';
import { MAT_CHECKBOX_CLICK_ACTION, DateAdapter } from '@angular/material';
import { UsercontributionComponent } from './dashboard/usercontribution/usercontribution.component';
import { ContributiondailognComponent } from './shared/contributiondailogn/contributiondailogn.component';
import { LoandetailComponent } from './dashboard/loandetail/loandetail.component';
import { LoanDetailDailogComponent } from './shared/loan-detail-dailog/loan-detail-dailog.component';
import { LoanrepaymentComponent } from './dashboard/loanrepayment/loanrepayment.component';
import { RepaymentdailogComponent } from './shared/repaymentdailog/repaymentdailog.component';
import { PayComponent } from './shared/pay/pay.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { Repayment } from './models/repayment';
import { ChartModule } from 'angular-highcharts';
import { PenaltyComponent } from './dashboard/penalty/penalty.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { UserreportComponent } from './dashboard/report/userreport/userreport.component';
import { LoandetailreportComponent } from './dashboard/report/loandetailreport/loandetailreport.component';
import { PenaltyreportComponent } from './dashboard/report/penaltyreport/penaltyreport.component';
import { SettingComponent } from './dashboard/setting/setting.component';
import { CardsComponent } from './dashboard/cards/cards.component';
import { AdminComponent } from './admin/admin.component';
import { GroupComponent } from './admin/group/group.component';
import { IndexComponent } from './admin/index/index.component';

import { GroupuserComponent } from './admin/groupuser/groupuser.component';
import { AdminloginComponent } from './default/adminlogin/adminlogin.component';
import { GroupdailogComponent } from './admin/group/groupdailog/groupdailog.component';
import { ContributionHistoryComponent } from './dashboard/contribution-history/contribution-history.component';
import { PayoutcalculatorComponent } from './dashboard/payoutcalculator/payoutcalculator.component';
import { GroupuserdailogComponent } from './admin/groupuser/groupuserdailog/groupuserdailog.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { ChangePasswordComponent } from './dashboard/change-password/change-password.component';
import { LoanactionComponent } from './shared/loanaction/loanaction.component';
import { ActiveGroupComponent } from './default/active-group/active-group.component';
import { LoanpdfComponent } from './dashboard/loanpdf/loanpdf.component';
import { RecoverPasswordComponent } from './default/recover-password/recover-password.component';
import { ApplyloanComponent } from './dashboard/applyloan/applyloan.component';
import { LoanapplicationdailogComponent } from './shared/loanapplicationdailog/loanapplicationdailog.component';
import { LoanHistoryComponent } from './dashboard/loan-history/loan-history.component';
import { LoanCompleteMsgComponent } from './shared/loan-complete-msg/loan-complete-msg.component';
import { OverdueLoanComponent } from './dashboard/overdue-loan/overdue-loan.component';
import { SuperAdminDashboardComponent } from './super-admin-dashboard/super-admin-dashboard.component';
import { HomeSadminComponent } from './super-admin-dashboard/home-sadmin/home-sadmin.component';
import { CardsSadminComponent } from './super-admin-dashboard/cards-sadmin/cards-sadmin.component';
import { ForbiddenComponent } from './dashboard/forbidden/forbidden.component';
import { ForbiddenAdminComponent } from './admin/forbidden-admin/forbidden-admin.component';
import { MemberlistComponent } from './dashboard/memberlist/memberlist.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HomeComponent,

    ProductComponent,
    DefaultComponent,
    RegisterComponent,
    LoginComponent,
    UserComponent,
    AdduserComponent,
    RolesComponent,
    RolesdailogComponent,
    UsercontributionComponent,
    ContributiondailognComponent,
    LoandetailComponent,
    LoanDetailDailogComponent,
    LoanrepaymentComponent,
    RepaymentdailogComponent,
    PayComponent,
    PenaltyComponent,
    UserreportComponent,
    LoandetailreportComponent,
    PenaltyreportComponent,
    SettingComponent,
    CardsComponent,
    AdminComponent,
    GroupComponent,
    IndexComponent,
    GroupuserComponent,
    AdminloginComponent,
    GroupdailogComponent,
    ContributionHistoryComponent,
    PayoutcalculatorComponent,
    GroupuserdailogComponent,
    ProfileComponent,
    ChangePasswordComponent,
    LoanactionComponent,
    ActiveGroupComponent,
    LoanpdfComponent,
    RecoverPasswordComponent,
    ApplyloanComponent,
    LoanapplicationdailogComponent,
    LoanHistoryComponent,
    LoanCompleteMsgComponent,
    OverdueLoanComponent,
    SuperAdminDashboardComponent,
    HomeSadminComponent,
    CardsSadminComponent,
    ForbiddenComponent,
    ForbiddenAdminComponent,
    MemberlistComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    NgxUiLoaderModule,
    ChartModule,
    MatMomentDateModule

  ],

  exports: [AdduserComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'check' }, { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }],
  entryComponents: [AdduserComponent, ContributiondailognComponent, RolesdailogComponent, LoanDetailDailogComponent, PayComponent, RepaymentdailogComponent, GroupdailogComponent, GroupuserdailogComponent, LoanactionComponent, LoanapplicationdailogComponent, LoanCompleteMsgComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
