import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepickerInputEvent } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { Loandetail } from 'src/app/models/loandetails';
import { User } from 'src/app/models/user';
import { error_messege } from 'src/app/models/error_massege'
import { Loanrepayment } from 'src/app/models/loanrepayment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment';
import { EventEmitter } from 'protractor';

@Component({
  selector: 'app-loan-detail-dailog',
  templateUrl: './loan-detail-dailog.component.html',
  styleUrls: ['./loan-detail-dailog.component.css'],

})
export class LoanDetailDailogComponent implements OnInit {
  users: User[] = [];
  error_messege = error_messege;
  fg: FormGroup;
  loandetail: Loandetail;
  loandetails: Loandetail[] = [];
  paymentYear: any[];
  paymentMonth: any[];
  loanrepayment: Loanrepayment;
  datavalue: any = 0;
  pdata: any = 0;
  idata: any = 0;
  state: string = "add";
  loanid: any;
  userid: any;
  loaninterestrate: any;
  amt: any;
  minDate: Date;
  date = new FormControl(new Date());
  newDate: any;
  constructor(
    public dialogRef: MatDialogRef<LoanDetailDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private cs: CommonService,
    private ngxloader: NgxUiLoaderService

  ) {

    this.minDate = new Date();
    dialogRef.disableClose = true;
    this.cs.fillSession();
    this.paymentYear = CommonService.PaymentYear;
    this.paymentMonth = CommonService.PaymentMonth;
    this.fg = this.fb.group({
      UserId: ['', [Validators.required]],
      GroupID: [this.cs.userSession.GroupID],
      InterestRate: ['', [Validators.required, Validators.pattern(/^(\d*\.)?\d+$/)]],
      Tenure: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      Principal: ['', [Validators.required, Validators.pattern(/^(\d*\.)?\d+$/)]],
      LoanStartDate: [new Date(), [Validators.required]],
      LoanEndDate: [''],
      // LoanStatus: [''],
      PaymentMonth: [''],
      PaymentYear: [''],
      OutstnadingPrincipal: [''],
      OutstandingInterest: [''],
      TotalInterest: [''],
      LoanStatus: ['true'],
      CreatedBy: [this.cs.userSession.Id],
      ModifiedBy: [this.cs.userSession.Id]
    })
  }

  ngOnInit() {
    this.cs.userlist().subscribe((users: any) => {
      this.users = users.ReturnObject;
      this.users.sort((a, b) => (a.FirstName > b.FirstName) ? 1 : -1);
    })
    this.loandetail = this.data.value
    if (this.loandetail.Id == 0) {
      this.state = "add";
      this.fg.patchValue({
        LoanStartDate: this.date.value
      })
      this.datechangeEvent(this.fg.value.LoanStartDate)

    } else {
      this.state = "edit"
      let oldStartDate = new Date(this.loandetail.LoanStartDate);
      // let oldEndDate = new Date(this.loandetail.LoanEndDate);
      let momentDate = moment(oldStartDate);
      this.fg.patchValue({
        Id: this.loandetail.Id,
        UserId: this.loandetail.UserId,
        GroupID: this.cs.userSession.GroupID,
        TotalInterest: this.loandetail.TotalInterest,
        InterestRate: this.loandetail.InterestRate,
        Tenure: this.loandetail.Tenure,
        Principal: this.loandetail.Principal,
        LoanStartDate: momentDate,
        LoanEndDate: this.loandetail.LoanEndDate,
        LoanStatus: this.loandetail.LoanStatus,
        OutstnadingPrincipal: this.loandetail.OutstnadingPrincipal,
        OutstandingInterest: this.loandetail.OutstandingInterest,
      })
      // this.fg.patchValue({
      //   LoanStartDate: this.loandetail.LoanStartDate
      // })
      this.datechangeEvent(momentDate);
      // this.datechangeEvent(this.fg.value.LoanStartDate)

      this.datavalue = this.fg.value.TotalInterest;
      this.pdata = this.fg.value.OutstnadingPrincipal;
      this.idata = this.fg.value.OutstandingInterest;
    }
    this.cs.getSetting().subscribe((res: any) => {
      this.loaninterestrate = res.ReturnObject[0].InterestRate;
    })
  }



  get f() {
    return this.fg;
  }


  onclick() {
    var addloan: Loandetail = this.fg.value;
    addloan.TotalInterest = this.datavalue;
    addloan.OutstandingInterest = this.idata;
    addloan.OutstnadingPrincipal = this.pdata;
    addloan.InterestRate = this.fg.value.InterestRate;

    this.userid = addloan.UserId;
    this.amt = addloan.Principal;
    var mon = (addloan.LoanStartDate.getMonth() + 1);
    var year = addloan.LoanStartDate.getFullYear();
    addloan.PaymentMonth = mon;
    addloan.PaymentYear = year;
    if (this.state == 'add') {

      this.cs.loanEligibility(this.userid, this.amt).subscribe((res: any) => {
        this.ngxloader.start();
        if (res == true) {
          this.cs.GetAvaliableFundCheck(this.amt).subscribe((res1: any) => {
            if (res1 == true){
              addloan.CreatedBy = this.cs.userSession.Id;
              addloan.ModifiedBy = this.cs.userSession.Id;
              this.cs.userLoanAdd(addloan).subscribe((res: any) => {
                if (res.ReturnObject != null && res.ReturnObject.length > 0) {
    
                  this.loanrepayment = new Loanrepayment();
                  this.loanrepayment.LoanId = res.ReturnObject[0].Id;
                  this.loanrepayment.UserId = res.ReturnObject[0].UserId;
                  this.loanrepayment.CreatedBy = this.cs.userSession.Id
                  this.loanrepayment.ModifiedBy = this.cs.userSession.Id
                  this.loanrepayment.GroupId = this.cs.userSession.GroupID;
                  this.loanrepayment.InterestRate = this.fg.value.InterestRate;
                  this.loanrepayment.LoanRepay = 0;
                  this.loanrepayment.InterestPaid = 0;
                  this.loanrepayment.LoanOutstanding = this.fg.value.OutstnadingPrincipal;
                  this.loanrepayment.InterestOwned = this.fg.value.OutstandingInterest;
                  this.loanrepayment.PaymentDate = this.fg.value.LoanStartDate;
                  this.loanrepayment.PaymentMonth = this.fg.value.PaymentMonth;
                  this.loanrepayment.MonthNo = this.fg.value.PaymentMonth;
                  this.loanrepayment.PaymentYear = this.fg.value.PaymentYear;
                  this.loanrepayment.LoanStatus = this.fg.value.LoanStatus;
                  this.cs.UserLoandetailsCreate(this.loanrepayment).subscribe((res1: any) => {
                    this.loanrepayment = res1;
                    if (res.ReturnObject.length > 0) {
                      this.cs.showSnackBar('success', 'User Loan Record Added successfully')
                      this.dialogRef.close();
                    }
                    else {
                      this.cs.showSnackBar('danger', 'Error Occured : Loan detail Record Not Created.');
                    }
                  })
                } else {
                  this.cs.showSnackBar('danger', res.DetailsError);
                }
              }, error => {
                this.ngxloader.stop();
                this.cs.showSnackBar('danger', 'Error Occured : Loan Record Not Created ');
              })
            }else
            {
              this.cs.showSnackBar('danger', 'Loan amount should not be greater than vaialble funds');
            }
          }, error => {
            this.ngxloader.stop();
            this.cs.showSnackBar('danger', 'Error Occured : Loan Record Not Created ');
          })
          
        } else {
          this.cs.showSnackBar('danger', 'User is not eligible to take loan more than savings');
        }
        this.ngxloader.stop();
      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured');
      })

    } else {

      this.state = "edit";
      this.cs.loanEligibility(this.userid, this.amt).subscribe((res: any) => {
        this.ngxloader.start();
        if (res == true) {
          this.cs.GetAvaliableFundCheck(this.amt).subscribe((res1: any) => {
            if (res1 == true){
          addloan.CreatedBy = this.cs.userSession.Id;
          addloan.ModifiedBy = this.cs.userSession.Id;
          this.cs.userLoanEdit(addloan).subscribe((res1: any) => {
            this.ngxloader.start();
            if (res1) {
              this.cs.showSnackBar('success', 'Loan Record Updated successfully');
              this.dialogRef.close();
            } else {
              this.cs.showSnackBar('danger', 'Approved or rejected loan can not modify');
            }
            this.ngxloader.stop();
          }, error => {
            this.ngxloader.stop();
            this.cs.showSnackBar('danger', 'Error Occured : Loan Record Not Updated');
          })
        }else
        {
          this.cs.showSnackBar('danger', 'Loan amount should not be greater than vaialble funds');
        }
      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured : Loan Record Not Created ');
      })

        } else {
          this.cs.showSnackBar('danger', 'User is not eligible to take loan more than savings');
        }
        this.ngxloader.stop();
      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured');
      })
    }

  }

  onchangePrincipalInterest() {
    var obj = this.fg.value;
    var calulatedInterest;
    obj.Principal = obj.principal == "" ? 0 : obj.Principal;
    obj.InterestRate = obj.InterestRate;
    calulatedInterest = obj.Principal * (obj.InterestRate / 100);
    this.datavalue = calulatedInterest;
    this.pdata = obj.Principal;
    this.idata = calulatedInterest;
  }

  cancel() {
    this.dialogRef.close();
  }

  onchangeTenure() {
    this.datechangeEvent(this.fg.value.LoanStartDate)
  }

  datechangeEvent(event) {

    var LoanStartDate = moment(this.fg.value.LoanStartDate);
    this.fg.patchValue({
      LoanStartDate: LoanStartDate.toDate(),
    });
    var date = moment(this.fg.value.LoanStartDate);
    var selectedTenureDate = moment(date.add(this.fg.value.Tenure, 'months'));
    this.fg.patchValue({
      LoanEndDate: selectedTenureDate.toDate(),
    });

  }

}

