import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { User } from 'src/app/models/user';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-memberlist',
  templateUrl: './memberlist.component.html',
  styleUrls: ['./memberlist.component.css']
})
export class MemberlistComponent implements OnInit {
  value: string;

  state: string = "";
  user: User = new User();
  users: User[] = [];
  displayedColumns: string[] = ['Id', 'AccountNumber', 'FirstName', 'LastName', 'Roles', 'Email', 'Gender', 'CellNumber', 'Active'];
  dataSource: MatTableDataSource<User>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(private cs: CommonService, public dailog: MatDialog, private ngxloader: NgxUiLoaderService, private route: Router) { }

  ngOnInit() {

    if (this.cs.userSession.Roles.RoleName == "Group Admin") {
      this.state = "admin";
      this.Bindusers();
      this.dataSource = null;
      this.displayedColumns = ['Id', 'AccountNumber', 'FirstName', 'LastName', 'Roles', 'Email', 'Gender', 'CellNumber', 'Active'];
    } else if (this.cs.userSession.Roles.RoleName == "Group User") {

      this.state = "user";
      this.Bindusers();
      this.dataSource = null;
      this.displayedColumns = ['Id', 'AccountNumber', 'FirstName', 'LastName', 'Roles', 'Email', 'Gender', 'CellNumber', 'Active'];
    }
  }


  Bindusers() {

    this.cs.userlist().subscribe((users: any) => {
      this.ngxloader.start();
      this.users = users.ReturnObject;
      this.patchMatchTable();
      this.ngxloader.stop()
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    });

  }

  patchMatchTable() {

    this.dataSource = new MatTableDataSource(this.users);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }







}
