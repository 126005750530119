import { Component, OnInit, ViewChild, ɵɵcontainerRefreshEnd } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Repayment } from 'src/app/models/repayment';
import { CommonService } from 'src/app/service/common.service';
import { User } from 'src/app/models/user';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PayComponent } from 'src/app/shared/pay/pay.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RepaymentdailogComponent } from 'src/app/shared/repaymentdailog/repaymentdailog.component';
import { Loanrepayment } from 'src/app/models/loanrepayment';
import { Loandetail } from 'src/app/models/loandetails';

@Component({
  selector: 'app-loanrepayment',
  templateUrl: './loanrepayment.component.html',
  styleUrls: ['./loanrepayment.component.css']
})
export class LoanrepaymentComponent implements OnInit {
  state: string = "default";
  state1: string = "";
  repayment: Repayment = null;
  repayments: Repayment[] = [];
  loanrepayment: Loanrepayment[];
  Loanrepayment: Loanrepayment;
  loan: any[] = [];
  selectedloan: any;
  loanid: any;
  loantype: any;
  loandetail: Loandetail;
  loandetails: Loandetail[] = [];
  users: User[];
  fg: FormGroup;
  displayedColumns: string[] = ['TransactionId', 'LoanId', 'UserName', 'InterestRate', 'LoanRepay', 'InterestPaid', 'LoanOutstanding', 'InterestOwned', 'TotalOutstanding', 'TotalRepaid', 'PaymentDate', 'PaymentMonth', 'PaymentYear', 'action'];
  dataSource: MatTableDataSource<Loanrepayment>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;


  constructor(private cs: CommonService, private fb: FormBuilder, private dailog: MatDialog, private ngxloader: NgxUiLoaderService) {
    this.fg = this.fb.group({
      user: ['']
    })
    this.cs.fillSession();
  }

  ngOnInit() {


    if (this.cs.userSession.Roles.RoleName == "Group Admin") {
      this.state1 = "admin";
      // this.loadUserContribution(this.cs.userSession.Id);
    } else {
      this.state1 = "user";
      this.loadUserContribution();
    }
    this.BindUser();
  }

  BindUser() {
    this.cs.approveuserloan().subscribe((users: any) => {
      this.loandetails = users.ReturnObject;
    })
  }

  // user1Repayment() {
  //   this.cs.userloanrecord(this.cs.userSession.Id).subscribe((res: any) => {
  //     this.repayments = res.ReturnObject;
  //     for (let i = 0; i < this.repayments.length; i++) {
  //       this.repayments[i]['FirstName'] = this.repayments[i].MstUser.Firstname;
  //     }
  //     this.dataSource = new MatTableDataSource(this.repayments);

  //   })
  // }

  patchMatchTable() {

    this.dataSource = new MatTableDataSource(this.loanrepayment);

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onchange(event: any) {
    this.selectedloan = event.value;
    this.loadContributions(this.selectedloan);
  }

  loadUserContribution() {
    this.cs.LoanDetailsbyuserFetch(this.cs.userSession.Id).subscribe((res1: any) => {
      this.ngxloader.start();
      this.loanrepayment = res1.ReturnObject
      for (let i = 0; i < this.loanrepayment.length; i++) {
        this.loanrepayment[i]['FirstName'] = this.loanrepayment[i].MstUser.FirstName;
        this.loanrepayment[i]['LastName'] = this.loanrepayment[i].MstUser.LastName;
        this.loanrepayment[i]['TotalOutstanding'] = this.loanrepayment[i].LoanOutstanding + this.loanrepayment[i].InterestOwned;
        this.loanrepayment[i]['TotalRepaid'] = this.loanrepayment[i].LoanRepay + this.loanrepayment[i].InterestPaid;
      }
      this.dataSource = null;
      this.dataSource = new MatTableDataSource(res1.ReturnObject);

      if (this.state1 == "user")
        this.displayedColumns = ['TransactionId', 'LoanId', 'UserName', 'InterestRate', 'LoanRepay', 'InterestPaid', 'LoanOutstanding', 'InterestOwned', 'TotalOutstanding', 'TotalRepaid', 'PaymentDate', 'PaymentMonth', 'PaymentYear'];
      else
        this.displayedColumns = ['TransactionId', 'LoanId', 'UserName', 'InterestRate', 'LoanRepay', 'InterestPaid', 'LoanOutstanding', 'InterestOwned', 'TotalOutstanding', 'TotalRepaid', 'PaymentDate', 'PaymentMonth', 'PaymentYear', 'action'];

      this.state = "record";
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }

  loadContributions(loanid) {
    this.cs.LoanDetailsFetch(loanid).subscribe((res1: any) => {
      this.ngxloader.start();
      this.loanrepayment = res1.ReturnObject
      for (let i = 0; i < this.loanrepayment.length; i++) {
        this.loanrepayment[i]['FirstName'] = this.loanrepayment[i].MstUser.FirstName;
        this.loanrepayment[i]['LastName'] = this.loanrepayment[i].MstUser.LastName;
        this.loanrepayment[i]['TotalOutstanding'] = this.loanrepayment[i].LoanOutstanding + this.loanrepayment[i].InterestOwned;
        this.loanrepayment[i]['TotalRepaid'] = this.loanrepayment[i].LoanRepay + this.loanrepayment[i].InterestPaid;
      }
      this.dataSource = null;
      this.dataSource = new MatTableDataSource(res1.ReturnObject);

      if (this.state1 == "user")
        this.displayedColumns = ['TransactionId', 'LoanId', 'UserName', 'InterestRate', 'LoanRepay', 'InterestPaid', 'LoanOutstanding', 'InterestOwned', 'TotalOutstanding', 'TotalRepaid', 'PaymentDate', 'PaymentMonth', 'PaymentYear'];
      else
        this.displayedColumns = ['TransactionId', 'LoanId', 'UserName', 'InterestRate', 'LoanRepay', 'InterestPaid', 'LoanOutstanding', 'InterestOwned', 'TotalOutstanding', 'TotalRepaid', 'PaymentDate', 'PaymentMonth', 'PaymentYear', 'action'];

      this.state = "record";
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }
  payrow() {

    this.cs.getSetting().subscribe((res: any) => {
      this.ngxloader.start();
      this.loantype = res.ReturnObject[0].LaonType
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })

    if (this.loanrepayment[0] == null) {
      this.cs.getloandetailrecord(this.selectedloan).subscribe((res: any) => {
        this.ngxloader.start();
        if (res.ReturnObject != null && res.ReturnObject.length > 0) {
          var loanre = new Loanrepayment();

          loanre.LoanId = res.ReturnObject[0].Id;
          loanre.InterestOwned = res.ReturnObject[0].OutstandingInterest;
          loanre.LoanOutstanding = res.ReturnObject[0].OutstnadingPrincipal;
          loanre.InterestRate = res.ReturnObject[0].InterestRate;
          loanre.GroupId = res.ReturnObject[0].GroupId;
          loanre.UserId = res.ReturnObject[0].UserId;
          this.loanrepayment.push(loanre);

          const dialogRef = this.dailog.open(PayComponent, {
            height: "400px",
            width: "500px",
            data: { userId: this.selectedloan, loandetail: this.loanrepayment }
          });

          dialogRef.afterClosed().subscribe(result => {
            this.loadContributions(this.selectedloan);
          });
        } else {
          this.cs.showSnackBar('danger', 'User has no active loan');
        }
        this.ngxloader.stop();
      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured');
      })

    } else {
      const dialogRef = this.dailog.open(PayComponent, {
        height: "450px",
        width: "500px",
        data: { loanId: this.selectedloan, loandetail: this.loanrepayment }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.loadContributions(this.selectedloan);
      });
    }

  }

  editPayment(row: any) {

    const dialogRef = this.dailog.open(RepaymentdailogComponent, {
      height: "450px",
      width: "500px",
      data: { value: row, loanId: this.selectedloan, loandetail: this.loanrepayment }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadContributions(this.selectedloan);
    });

  }
}
