import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-cards-sadmin',
  templateUrl: './cards-sadmin.component.html',
  styleUrls: ['./cards-sadmin.component.css']
})
export class CardsSadminComponent implements OnInit {

  @Input() bgClass: string;
  @Input() icon: string;
  @Input() count: number;
  @Input() label: string;
  @Input() data: number;
  @Output() event: EventEmitter<any> = new EventEmitter();

  constructor(private cs: CommonService) { }

  ngOnInit() {

  }

}
