import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as jsPDF from 'jspdf';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Repayment } from 'src/app/models/repayment';
import { Loanrepayment } from 'src/app/models/loanrepayment';
import { Loandetail } from 'src/app/models/loandetails';
import { User } from 'src/app/models/user';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-loanpdf',
  templateUrl: './loanpdf.component.html',
  styleUrls: ['./loanpdf.component.css']
})
export class LoanpdfComponent implements OnInit {

  state: string = "default";
  state1: string = "";
  repayment: Repayment = null;
  repayments: Repayment[] = [];
  loanrepayment: Loanrepayment[] = [];
  loan: any[] = [];
  selecteduser: any;
  loanid: any;
  loantype: any;
  loandetail: Loandetail;
  loandetails: Loandetail[] = [];
  users: User[];
  name = 'Angular Html To Pdf ';
  fg: FormGroup;
  date: Date;
  @ViewChild('pdfTable', { static: true }) pdfTable: ElementRef;
  constructor(
    private cs: CommonService,
    private fb: FormBuilder,
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    route: Router) {
    this.fg = this.fb.group({
      user: ['']
    });

    this.cs.fillSession();
  }

  ngOnInit() {
    this.date = new Date();
    this.loaduserinfo();
  }

  public captureScreen() {

    var data = document.getElementById('pdfTable');

    var HTML_Width = document.getElementById('pdfTable').clientWidth;
    var HTML_Height = document.getElementById('pdfTable').clientHeight;
    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    html2canvas(data).then(canvas => {

      canvas.getContext('2d');

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);

      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width, PDF_Height);
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save("LoanStatement" + this.loandetail['MstUser'].AccountNumber + ".pdf");
    });
  }

  loaduserinfo() {
    this.activatedRoute.params.subscribe(params => {
      let lid = params.Id;
      this.cs.loandetailbyLoanId(lid).subscribe((res: any) => {
        this.loandetail = res.ReturnObject[0];
        this.loadUserContribution();
      });
    });
  }

  loadUserContribution() {
    this.activatedRoute.params.subscribe(params => {
      let lid = params.Id;
      let uid = params.uid
      this.cs.getpaymentHistory(uid, lid).subscribe((res1: any) => {
        this.loanrepayment = res1.ReturnObject;
        this.loanrepayment.sort((a, b) => 0 - (a > b ? -1 : 1));
      });
    });
  }

  backClicked() {
    this._location.back();
  }
}


