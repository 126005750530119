import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from '../models/config';
import { User } from '../models/user';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material';
import { Usercontribution } from '../models/usercontribution';
import { Role } from '../models/roles';
import { AES, enc } from 'crypto-js';
import { Loandetail } from '../models/loandetails';
import { LoandetailComponent } from '../dashboard/loandetail/loandetail.component';
import * as Highcharts from 'highcharts';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
import { Loanrepayment } from '../models/loanrepayment';
import { Group } from '../models/group';
import { environment } from 'src/environments/environment';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public config = environment;
  userSession: any;
  adminSession: any
  public user: User;

  public static PaymentYear: any[] = [2019, 2020, 2021,2022,2023,2024,2025];
  public static PaymentMonth: any[] = [
    { value: 1, viewValue: 'January' },
    { value: 2, viewValue: 'February' },
    { value: 3, viewValue: 'March' },
    { value: 4, viewValue: 'April' },
    { value: 5, viewValue: 'May' },
    { value: 6, viewValue: 'June' },
    { value: 7, viewValue: 'July' },
    { value: 8, viewValue: 'August' },
    { value: 9, viewValue: 'September' },
    { value: 10, viewValue: 'October' },
    { value: 11, viewValue: 'November' },
    { value: 12, viewValue: 'December' }

  ];
  constructor(private http: HttpClient, private snackbar: MatSnackBar) {
  }

  userlogin(uName: string, password: string): Observable<any> {
    const usr = new User();
    usr.Email = uName;
    usr.Password = password;
    // return this.http.get<any>(this.config.url + '/Users/Login/' + uName + '/' + this.standardEncoding(password)
    //   , {
    //     params: new HttpParams(),
    //     headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
    //       .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
    //   });
    return this.http.post<any>(this.config.url + '/Users/Login', usr
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  token(username: string, password: string, scope: string): Observable<any> {

    let body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', this.standardEncoding(password))
      .set('scope', scope)
    return this.http.post(environment.tokenapi, body.toString(),
      {

        params: new HttpParams(),
        headers: new HttpHeaders()
          .set("Authorization", "Bearer ")
      });

  }

  standardEncoding(v: string): string {
    return encodeURIComponent(v)
      .replace('@', '%40')
      .replace(':', '%3A')
      .replace('$', '%24')
      .replace(',', '%2C')
      .replace(';', '%3B')
      .replace('+', '%2B')
      .replace('=', '%3D')
      .replace('?', '%3F')
      .replace('/', '%2F');
  }

  RegisterGroup(user: User): Observable<any> {
    return this.http.post<any>(this.config.url + '/Users/RegisterGroup', user);
  }

  adminlogin(uName: string, password: string): Observable<any> {
    const usr = new User();
    usr.Email = uName;
    usr.Password = password;
    // return this.http.get<any>(this.config.url + '/Users/Login1/' + uName + '/' + this.standardEncoding(password)
    //   , {
    //     params: new HttpParams(),
    //     headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
    //       .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
    //   });
    return this.http.post<any>(this.config.url + '/Users/Login1', usr
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  GetEmailExist(uName: string): Observable<any> {
    return this.http.get<any>(this.config.url + '/Users/GetEmailExist/' + uName);
  }

  GetActivateGroup(uName: string, otpcode: number): Observable<any> {
    return this.http.get<any>(this.config.url + '/Users/GetActivateGroup/' + uName + '/' + otpcode);
  }

  GetForgotPassword(uName: string): Observable<any> {
    return this.http.get<any>(this.config.url + '/Users/GetForgotPassword/' + uName);
  }

  adminlist(): Observable<User[]> {
    this.fillSession();
    return this.http.get<User[]>(this.config.url + '/Users/getalladminusers'
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  userlist(): Observable<User[]> {
    this.fillSession();
    return this.http.get<User[]>(this.config.url + '/Users/allusers/' + this.userSession.GroupID
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  GetUserById(): Observable<User[]> {
    this.fillSession();
    return this.http.get<User[]>(this.config.url + '/Users/GetUserById/' + this.userSession.Id
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  useradd(user: User): Observable<any> {
    return this.http.post<any>(this.config.url + '/Users/Register', user = user
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  userupdate(user: User): Observable<any> {
    return this.http.post<any>(this.config.url + '/Users/UpdateUser', user = user
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  GetDeleteUserById(uid: number): Observable<any> {
    return this.http.get<any>(this.config.url + '/Users/GetDeleteUserById/' + uid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  roleslist(gid: any): Observable<any[]> {
    return this.http.get<any[]>(this.config.url + '/Roles/allroles/' + gid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  roleregister(role: Role): Observable<any> {
    return this.http.post<any>(this.config.url + '/Roles/Create', role = role
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  roleupdate(role: Role): Observable<any> {
    return this.http.post<any>(this.config.url + '/Roles/Modify', role
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }



  showSnackBar(type: string, message: string, duration?: number) {
    if (duration == null)
      duration = 10000;
    let matSnackbarConfig: MatSnackBarConfig = {
      panelClass: 'bg-' + type,
      duration: duration,
      horizontalPosition: 'end',
      verticalPosition: 'bottom'
    };
    this.snackbar.open(message, 'Dismiss', matSnackbarConfig);
  }


  contributionlist(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/UserContribution/GeByGroupID/' + this.userSession.GroupID
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }


  contributionuser(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/UserContribution/GeByGroupIdandUserID/' + this.userSession.GroupID + '/' + this.userSession.Id
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  contributionRegister(usercontribution: Usercontribution): Observable<any> {
    return this.http.post<any>(this.config.url + '/UserContribution/Create', usercontribution = usercontribution
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  contributionUpdate(usercontribution: Usercontribution): Observable<any> {
    return this.http.post<any>(this.config.url + '/UserContribution/Modify', usercontribution
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  contributionHistory(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/UserContribution/GetAllByGroupID/' + this.userSession.GroupID
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      })
  }

  CalculateContribution(userid: any): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/UserContribution/GetCalculateContribution/' + this.userSession.GroupID + '/' + userid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      })
  }

  contributionCheck(uid: any, mno: any, year: any): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/UserContribution/GetCheckContributionExist/' + this.userSession.GroupID + '/' + uid + '/' + mno + '/' + year
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  userloan(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanDetail/GetByGroupId/' + this.userSession.GroupID
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  GetOverdueLoan(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanDetail/GetOverdueLoanDetailByGroupId/' + this.userSession.GroupID
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  GetLoanDetailHistory(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanDetail/GetLoanDetailHistoryByGroupId/' + this.userSession.GroupID
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  GetLoanDetailHistoryByUserId(uid: number): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanDetail/GetLoanDetailHistoryByUserId/' + this.userSession.GroupID + '/' + uid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }


  approveuserloan(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanDetail/GetByGroupIdForRepayment/' + this.userSession.GroupID
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  GetLoanApproval(aid: number, lid: number, comment: string, LAstatus: boolean): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanDetail/GetLoanApproval/' + aid + '/' + lid + '/' + comment + '/' + LAstatus
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  userloanrecord(Id: number): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanDetail/GetLoanRepayDetailForUserByGroupIdandUserId/' + this.userSession.GroupID + '/' + Id
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  user1loanrecord(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanDetail/GetByGroupId/' + this.userSession.GroupID + '/' + this.userSession.Id
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  loanCheck(uid: any, mno: any, year: any, lid: number): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanRepaymentDetail/GetCheckLoanRepaymentDetailExist/' + this.userSession.GroupID + '/' + uid + '/' + mno + '/' + year + '/' + lid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  userPayPost(usercontribution: Usercontribution[]): Observable<any> {
    return this.http.post<any>(this.config.url + '/UserContribution/CreateRepay', usercontribution
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  userRepayRecord(uid: number, lid: number): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanDetail/GetByLoanRepayDetails/' + this.userSession.GroupID + '/' + uid + '/' + lid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  userLoanAdd(loandetail: Loandetail): Observable<any> {
    return this.http.post<any>(this.config.url + '/LoanDetail/Create', loandetail = loandetail
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  userLoanAddByUser(loandetail: Loandetail): Observable<any> {
    return this.http.post<any>(this.config.url + '/LoanDetail/CreateLoanByUser', loandetail = loandetail
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  userLoanApplication(loandetail: Loandetail): Observable<any> {
    return this.http.post<any>(this.config.url + '/LoanDetail/CreateUserLoan', loandetail = loandetail
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  userLoanEdit(loandetail: Loandetail): Observable<any> {
    return this.http.post<any>(this.config.url + '/LoanDetail/Modify', loandetail
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  userLoanModify(loandetail: Loandetail): Observable<any> {
    return this.http.post<any>(this.config.url + '/LoanDetail/ModifyUserLoan', loandetail
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  UserLoandetailsCreate(loanrepayments: Loanrepayment): Observable<any> {
    this.fillSession();
    return this.http.post<any>(this.config.url + '/LoanRepaymentDetail/Create', loanrepayments
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  Loanrepayment(loanrepayments: Loanrepayment): Observable<any> {
    this.fillSession();
    return this.http.post<any>(this.config.url + '/LoanRepaymentDetail/Modify', loanrepayments
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }


  LoanDetailsbyuserFetch(uid: any): Observable<any> {
    return this.http.get<any>(this.config.url + '/LoanRepaymentDetail/GetByGroupIdandUserId/' + this.userSession.GroupID + '/' + uid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  LoanDetailsFetch(lid: any): Observable<any> {
    return this.http.get<any>(this.config.url + '/LoanRepaymentDetail/GetByGroupIdandLoanId/' + this.userSession.GroupID + '/' + lid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  getpaymentrecord(userid: any, loanid: any): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanRepaymentDetail/GetLoanRepayDetailByGroupIdUserIdLoanId/' + this.userSession.GroupID + '/' + userid + '/' + loanid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  loandetailbyLoanId(lid: any): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanDetail/GetById/' + lid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      })
  }

  getpaymentHistory(userid: any, loanid: any): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanRepaymentDetail/GetLoanRepayDetailHistoryByGroupIdUserIdLoanId/' + this.userSession.GroupID + '/' + userid + '/' + loanid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  getloandetailrecord(uid: any): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanDetail/GetByGroupId/' + this.userSession.GroupID + '/' + uid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }


  userreport(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/report/GetYearlySavingsCumulativeReport/' + this.userSession.GroupID
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  penaltyeport(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/report/GetYearlyPenaltyReport/' + this.userSession.GroupID
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  GetShareUsersreport(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/report/GetShareUsersContribution/' + this.userSession.GroupID
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  GetShareUsersreportBySAdmin(gid: number): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/report/GetShareUsersContribution/' + gid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  getSetting(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/settings/GetSetting/' + this.userSession.GroupID
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  updateInterestrate(obj: any): Observable<any> {
    return this.http.post<any>(this.config.url + '/settings/Modify', obj
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  loanEligibility(uid: any, amt: any): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanDetail/GetLoanEligibility/' + this.userSession.GroupID + '/' + uid + '/' + amt
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  GetAvaliableFundCheck(amt: any): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanDetail/GetAvaliableFundCheck/' + this.userSession.GroupID + '/' + amt
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  Getrepaymentdetails(uid: any, loanid: any): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/LoanRepaymentDetail/Getrepaymentdetails/' + this.userSession.GroupID + '/' + uid + '/' + loanid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }


  getGroups(): Observable<any> {
    return this.http.get<any>(this.config.url + '/GroupDetails/GetAll'
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  addGroups(group: Group): Observable<any> {
    return this.http.post<any>(this.config.url + '/GroupDetails/Create', group
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  updateGroups(group: Group): Observable<any> {
    return this.http.post<any>(this.config.url + '/GroupDetails/Modify', group
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  userPayOutCalculate(uid: any, payout: any): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/report/GetProjectedPayout/' + this.userSession.GroupID + '/' + uid + '/' + payout
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  dashboardCards(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/report/GetDashboardCards/' + this.userSession.GroupID
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  dashboardCardsBySAdmin(gid: number): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/report/GetDashboardCards/' + gid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  userdashboardCards(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/report/GetUserDashboardCards/' + this.userSession.GroupID + '/' + this.userSession.Id
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  setUser(user: User) {
    delete user.Password;
    const encryptedMessage = AES.encrypt(JSON.stringify(user), 'pr@tik').toString();
    localStorage.setItem("user", encryptedMessage);
  }

  UpdateProfile(obj: any): Observable<any> {
    return this.http.post<any>(this.config.url + '/Users/UpdateUserProfile', obj
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  password_change(obj: any): Observable<any> {
    return this.http.post<any>(this.config.url + '/Users/ChangePassword', obj
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }
  getUser() {
    var encString = localStorage.getItem("user");
    var bytes = AES.decrypt(encString, 'pr@tik');
    var plaintext = bytes.toString(enc.Utf8);
    return JSON.parse(plaintext);
  }
  fillSession() {
    if (this.userSession == undefined)
      this.userSession = JSON.parse(localStorage.getItem('userSessionData'));
  }

  createChart(el, cfg) {
    Highcharts.chart(el, cfg);
  }

  cumulative(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/report/GetYearlyCumulativeDataByGroupId/' + this.userSession.GroupID
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  cumulativeSAdmin(gid: number): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/report/GetYearlyCumulativeDataByGroupId/' + gid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  usercumulative(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/report/GetYearlyCumulativeDataByGroupIdAndUserId/' + this.userSession.GroupID + '/' + this.userSession.Id
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }

  pie(): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/report/GetYearlyUsersContribution/' + this.userSession.GroupID
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }


  pieSAdmin(gid: number): Observable<any> {
    this.fillSession();
    return this.http.get<any>(this.config.url + '/report/GetYearlyUsersContribution/' + gid
      , {
        params: new HttpParams(),
        headers: new HttpHeaders()//.set('Content-Type', 'application/json; charset=utf-8')
          .set("Authorization", localStorage.token_type + " " + localStorage.access_token)
      });
  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  roleMatch(allowedRoles): boolean {
    var isMatch = false;
    var userRoles: string[] = JSON.parse(localStorage.role);
    allowedRoles.forEach(element => {
      if (userRoles.indexOf(element) > -1) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }

}