import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { User } from 'src/app/models/user';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  users: User[];
  user: User;
  fg: FormGroup;
  maxNewDate = new Date();
  // maxDate = new Date(2020, 0, 1);
  // date = new Date((new Date().getTime() - 3888000000));
  constructor(private cs: CommonService, private fb: FormBuilder, private route: Router, private ngxloader: NgxUiLoaderService) {
    this.cs.fillSession();
    this.maxNewDate.setUTCFullYear(this.maxNewDate.getUTCFullYear() - 5);
    this.fg = this.fb.group({
      GroupId: [this.cs.userSession.GroupID],
      FirstName: [''],
      LastName: [''],
      Email: [''],
      CellNumber: [''],
      DOB: [''],
      // Address: [''],
      // Occupation: [''],
    })
  }

  ngOnInit() {


    this.cs.GetUserById().subscribe((res: any) => {
      this.ngxloader.start();
      this.users = res.ReturnObject;
      this.fg = this.fb.group({
        GroupId: [this.cs.userSession.GroupID],
        FirstName: [this.users[0].FirstName],
        LastName: [this.users[0].LastName],
        Email: [this.users[0].Email],
        CellNumber: [this.users[0].CellNumber],
        DOB: [this.users[0].DOB],
        // Address: [this.user.Address],
        // Occupation: [this.user.Occupation]],
        Gender: [this.users[0].Gender.trim()],
        Active: [this.users[0].Active],
        ModifiedBy: [this.cs.userSession.Id],
        Password: [this.users[0].Password]
      })
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }

  update() {
    var obj = this.fg.value;
    obj.CreatedBy = this.cs.userSession.Id;
    obj.ModifiedBy = this.cs.userSession.Id;
    obj.Id = this.cs.userSession.Id;
    this.cs.UpdateProfile(obj).subscribe((res: any) => {
      this.ngxloader.start();
      this.cs.showSnackBar('success', 'Profile updated successfully');
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }

  cancel() {
    this.route.navigate(['dashboard']);
  }
}
