import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  data = null;
  constructor(private cs: CommonService) { }

  ngOnInit() {

    // this.cs.studlist().subscribe((data: any[]) => {
    //   this.data = data;
    // })
  }

}
