import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Loandetail } from 'src/app/models/loandetails';
import { LoanDetailDailogComponent } from 'src/app/shared/loan-detail-dailog/loan-detail-dailog.component';
import { CommonService } from 'src/app/service/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LoanactionComponent } from 'src/app/shared/loanaction/loanaction.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-loandetail',
  templateUrl: './loandetail.component.html',
  styleUrls: ['./loandetail.component.css']
})
export class LoandetailComponent implements OnInit {

  loandetail: Loandetail = new Loandetail();
  loandetails: Loandetail[] = [];
  loaninterestrate: any;
  state: string = "";
  irate: number;
  displayedColumns: string[] = ['Id', 'FirstName', 'Tenure', 'Principal', 'InterestRate', 'LoanStartDate', 'LoanEndDate', 'OutstnadingPrincipal', 'OutstandingInterest', 'TotalInterest', 'LoanStatus', 'action'];
  dataSource: MatTableDataSource<Loandetail>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(private dailog: MatDialog, private cs: CommonService, private ngxloader: NgxUiLoaderService, private ChangeDetectorRef: ChangeDetectorRef, private activatedRoute: ActivatedRoute, private router: Router) {
    this.cs.fillSession();
    if (this.cs.userSession.Roles.RoleName == "Group Admin") {
      this.state = "admin";
    } else {
      this.state = "user";
    }
  }

  ngOnInit() {
    this.cs.getSetting().subscribe((res: any) => {
      this.irate = res.ReturnObject[0].InterestRate
    })
    if (this.cs.userSession.Roles.RoleName == "Group Admin") {
      // this.state = "admin";
      // this.state = "hide";
      // this.state = "show";
      this.BindUserLoan();
      this.dataSource = null;
      this.displayedColumns = ['Id', 'FirstName', 'Tenure', 'Principal', 'InterestRate', 'LoanStartDate', 'LoanEndDate', 'OutstnadingPrincipal', 'OutstandingInterest', 'TotalInterest', 'Comments', 'LoanStatus', 'Approvalstatus', 'action'];
    } else {
      // this.state = "user";
      this.BindUserLoan();
      this.dataSource = null;
      this.displayedColumns = ['Id', 'FirstName', 'Tenure', 'Principal', 'InterestRate', 'LoanStartDate', 'LoanEndDate', 'OutstnadingPrincipal', 'OutstandingInterest', 'TotalInterest', 'Comments', 'LoanStatus', 'Approvalstatus'];
    }



  }

  BindUserLoan() {
    this.cs.userloan().subscribe((res: any) => {

      this.loandetails = res.ReturnObject;
      for (let i = 0; i < this.loandetails.length; i++) {
        if (this.loandetails[i].Approvalstatus == null) {
          this.loandetails[i].Approvalstatus = "Pending";
          // this.state = "show";
          // this.state = "admin";
        } else if (this.loandetails[i].Approvalstatus == true) {
          this.loandetails[i].Approvalstatus = "Approved";
          // this.state = "hide";
          // this.state = "admin";
        } else {
          this.loandetails[i].Approvalstatus = "Rejected";
          // this.state = "hide";
          // this.state = "admin";
        }

        if (this.loandetails[i].LoanStatus = true) {
          this.loandetails[i].LoanStatus = "Active"
        } else {
          this.loandetails[i].LoanStatus = "Paid"
        }
      }

      for (let i = 0; i < this.loandetails.length; i++) {
        this.loandetails[i]['FirstName'] = this.loandetails[i].MstUser.FirstName;
        this.loandetails[i]['LastName'] = this.loandetails[i].MstUser.LastName;
      }
      this.dataSource = new MatTableDataSource(this.loandetails);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    }, error => {

      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }

  BindUser1Loan() {
    this.cs.user1loanrecord().subscribe((res: any) => {
      this.ngxloader.start();
      this.loandetails = res.ReturnObject;
      for (let i = 0; i < this.loandetails.length; i++) {
        this.loandetails[i]['FirstName'] = this.loandetails[i].MstUser.FirstName;
        this.loandetails[i]['LastName'] = this.loandetails[i].MstUser.LastName;
      }
      this.dataSource = new MatTableDataSource(this.loandetails);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }

  patchMatchTable() {
    this.dataSource = new MatTableDataSource(this.loandetails);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  addLoan(Lid: number) {
    this.loandetail.Id = Lid;
    this.loandetail.LoanStartDate = new Date();
    this.loandetail.InterestRate = this.irate;

    const dialogRef = this.dailog.open(LoanDetailDailogComponent, {
      height: "550px",
      width: "500px",
      data: { value: this.loandetail }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.BindUserLoan();
    });

  }

  editLoan(row: any) {
    const dialogRef = this.dailog.open(LoanDetailDailogComponent, {
      height: "500px",
      width: "500px",
      data: { value: row }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.BindUserLoan();
    });

  }

  LoanApproval(row: any) {

    const dialogRef = this.dailog.open(LoanactionComponent, {

      data: { value: row }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.BindUserLoan();
    });

  }

  viewdetails1(row: any) {

    row.Id

    this.router.navigate(['../loanpdf', row.Id, row.UserId], { relativeTo: this.activatedRoute });
  }


}
