export class Loanrepayment {
    Id?: number;
    UserId: number;
    UserName: string;
    GroupId?: number;
    LoanId?: number;
    InterestRate?: number;
    LoanRepay?: number;
    InterestPaid?: number;
    LoanOutstanding?: number;
    InterestOwned?: number;
    PaymentDate: Date;
    PaymentMonth: any;
    MonthNo: number;
    MstUser: any;
    PaymentYear: any;
    LoanStatus: any;
    TotalRepaid: number;
    TotalOutstanding: number;
    CreatedBy: number;
    ModifiedBy: number;
}