import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Loandetail } from 'src/app/models/loandetails';
import { CommonService } from 'src/app/service/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-overdue-loan',
  templateUrl: './overdue-loan.component.html',
  styleUrls: ['./overdue-loan.component.css']
})
export class OverdueLoanComponent implements OnInit {
  loandetail: Loandetail = new Loandetail();
  loandetails: Loandetail[] = [];
  loaninterestrate: any;
  state: string = "admin";
  irate: number;
  displayedColumns: string[] = ['Id', 'FirstName', 'Tenure', 'Principal', 'InterestRate', 'LoanStartDate', 'LoanEndDate', 'OutstnadingPrincipal', 'OutstandingInterest', 'TotalInterest', 'action'];
  dataSource: MatTableDataSource<Loandetail>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(private dailog: MatDialog, private cs: CommonService, private ngxloader: NgxUiLoaderService, private ChangeDetectorRef: ChangeDetectorRef, private activatedRoute: ActivatedRoute, private router: Router) {
    this.cs.fillSession();
  }

  ngOnInit() {
    this.cs.getSetting().subscribe((res: any) => {
      this.irate = res.ReturnObject[0].InterestRate
    })

    if (this.cs.userSession.Roles.RoleName == "Group Admin") {
      this.state = "admin";
      this.BindOverdue();
      this.dataSource = null;
      this.displayedColumns = ['Id', 'FirstName', 'Tenure', 'Principal', 'InterestRate', 'LoanStartDate', 'LoanEndDate', 'OutstnadingPrincipal', 'OutstandingInterest', 'TotalInterest', 'Comments', 'action'];
    }



  }

  BindOverdue() {
    this.cs.GetOverdueLoan().subscribe((res: any) => {

      this.loandetails = res.ReturnObject;


      for (let i = 0; i < this.loandetails.length; i++) {
        this.loandetails[i]['FirstName'] = this.loandetails[i].MstUser.FirstName;
        this.loandetails[i]['LastName'] = this.loandetails[i].MstUser.LastName;
      }
      this.dataSource = new MatTableDataSource(this.loandetails);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    }, error => {

      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }


  patchMatchTable() {
    this.dataSource = new MatTableDataSource(this.loandetails);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }






  viewdetails1(row: any) {

    row.Id

    this.router.navigate(['../loanpdf', row.Id, row.UserId], { relativeTo: this.activatedRoute });
  }


}
