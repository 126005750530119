import { Component, OnInit } from '@angular/core';
import { error_messege } from '../../models/error_massege';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CustomValidators } from 'src/app/modules/custom-validators';

@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.scss']
})
export class AdminloginComponent implements OnInit {

  error_messege = error_messege;
  adminData: any;
  fg: FormGroup;
  router: string;
  state: string = "";
  constructor(private fb: FormBuilder, private cs: CommonService, private route: Router, private ngxloader: NgxUiLoaderService) {
    localStorage.clear();
    this.fg = this.fb.group({
      Email: ['', [Validators.compose([Validators.required, Validators.email, CustomValidators.trimValidator])]],
      password: ['', [Validators.required,]]
    })
    //Password  Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/)
    this.router = route.url;

    this.fg.controls.Email.valueChanges.subscribe((val) => {
      this.fg.patchValue(
        {
          Email: val.trim()
        },
        { emitEvent: false })
    });
  }

  ngOnInit() {

  }

  get f() {
    return this.fg;
  }


  login() {
    var obj = this.fg.value;
    let scope = "1";//scope define superadmin or normal user
    localStorage.clear();
    this.cs.token(obj.Email, obj.password, scope).subscribe((res1: any) => {
      localStorage.token_type = res1.token_type;
      localStorage.access_token = res1.access_token;
      localStorage.role = res1.role;
      this.cs.adminlogin(obj.Email, obj.password).subscribe((res: any) => {
        this.ngxloader.start();
        if (res.ReturnObject == null) {
          this.cs.showSnackBar('danger', res.DetailsError);
          this.state = "sms"
        } else {
          this.adminData = res.ReturnObject[0];


          localStorage.adminSessionData = JSON.stringify(this.adminData);
          this.cs.adminSession = JSON.parse(localStorage.adminSessionData);


          this.route.navigate(['admin/super-admin-dashboard']);

          this.cs.showSnackBar('success', 'Login Successfully!');
        }
        this.ngxloader.stop();
      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured');
      });
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', error.error.error_description);
    });
  }
}
