import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { error_messege } from 'src/app/models/error_massege';
import { User } from 'src/app/models/user';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CompareValidation, CompareValidation2 } from 'src/app/models/validators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CustomValidators } from 'src/app/modules/custom-validators';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  state: string = "";
  changeFormGroup: FormGroup;
  error_messege = error_messege;
  user: User;
  hide = true;
  hiden = true;
  hidec = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private _formBuilder: FormBuilder, private cs: CommonService, private router: Router, private title: Title, private ngxloader: NgxUiLoaderService) {
    this.cs.fillSession();
  }

  ngOnInit() {
    // this.user = this.commonService.getUser();
    this.changeFormGroup = this._formBuilder.group({
      Id: [this.cs.userSession.Id],
      GroupId: [this.cs.userSession.GroupID],
      // old_password: ['', Validators.required],
      // password: ['', [Validators.required, Validators.minLength(6)],Validators.pattern('/^[a-zA-Z0-9!@#$%^&*()]+$/')],
      password: ['', Validators.compose([
        Validators.required,
        // check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true
        }),
        // check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        // check whether the entered password has a lower case letter
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        // check whether the entered password has a special character
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          // /[ !@#$%^&*()_+-]/,
          {
            hasSpecialCharacters: true
          }
        ),
        Validators.minLength(8)
      ])],
      confirm_password: ['', Validators.required],
    }, { validator: CompareValidation2 });

    this.title.setTitle("Change Password");
  }
  change_password() {
    var obj = this.changeFormGroup.value;

    this.cs.password_change(obj).subscribe((resp: any) => {
      this.ngxloader.start();
      if (resp == true) {
        this.cs.showSnackBar('success', 'Change Password Successfully');
      } else {
        this.cs.showSnackBar('error', 'Change Password not Successfully');
      }
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    });


  }
  get f() {
    return this.changeFormGroup;
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
