import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../service/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,private cs: CommonService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot)//: Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
    {
    // return true;
    if (localStorage.getItem("userSessionData")) {
      if (localStorage.getItem("userSessionData") == null) {
        this.router.navigate(['login']);
        return false;
      }
      else if(localStorage.getItem("userSessionData") != null){
        let roles = next.data["roles"] as Array<string>;
        if (roles) {
          var match = this.cs.roleMatch(roles);
          if (match) return true;
          else {
            this.router.navigate(['dashboard/forbidden']);
            return false;
          }
        }
      }
      else{
          return true;
      }
    } else if (localStorage.getItem("adminSessionData")) {
      if (localStorage.getItem("adminSessionData") == null) {
        this.router.navigate(['alogin']);
        return false;
      }
      else if(localStorage.getItem("adminSessionData") != null){
        let roles = next.data["roles"] as Array<string>;
        if (roles) {
          var match = this.cs.roleMatch(roles);
          if (match) return true;
          else {
            this.router.navigate(['admin/forbidden-admin']);
            return false;
          }
        }
      }
      else {
        return true;
      }
    }
    else{
      this.router.navigate(['login']);
    }
  }
}
