import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Usercontribution } from 'src/app/models/usercontribution';
import { CommonService } from 'src/app/service/common.service';
import { User } from 'src/app/models/user';
import { ContributiondailognComponent } from 'src/app/shared/contributiondailogn/contributiondailogn.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-usercontribution',
  templateUrl: './usercontribution.component.html',
  styleUrls: ['./usercontribution.component.css']
})
export class UsercontributionComponent implements OnInit {

  state: string = "admin"
  user: User = new User();
  users: User[] = [];
  usercontribution: Usercontribution = new Usercontribution;
  usercontributions: Usercontribution[] = [];
  displayedColumns: string[] = ['Id', 'FirstName', 'UserContribution1', 'Paymentdate', 'PaymentMonth', 'PaymentYear', 'ContributionType', 'CumulativeSavings', 'InterestOnSavings', 'CumulativeWithInterest', 'Penalty', 'action'];
  dataSource: MatTableDataSource<Usercontribution>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(private cs: CommonService, private dailog: MatDialog, private ngxloader: NgxUiLoaderService) {
    this.cs.fillSession();
  }
  ngOnInit() {
    if (this.cs.userSession.Roles.RoleName == "Group Admin") {
      this.state = "admin";
      this.BindContribution();
      this.dataSource = null;
      this.displayedColumns = ['Id', 'FirstName', 'UserContribution1', 'Paymentdate', 'PaymentMonth', 'PaymentYear', 'ContributionType', 'CumulativeSavings', 'InterestOnSavings', 'CumulativeWithInterest', 'Penalty', 'action'];
    } else {
      this.state = "user";
      this.BindContributionuser();
      this.dataSource = null;
      this.displayedColumns = ['Id', 'FirstName', 'UserContribution1', 'Paymentdate', 'PaymentMonth', 'PaymentYear', 'ContributionType', 'CumulativeSavings', 'InterestOnSavings', 'CumulativeWithInterest', 'Penalty'];
    }
  }

  BindContribution() {
    this.cs.contributionlist().subscribe((res: any) => {
      this.ngxloader.start()
      this.usercontributions = res.ReturnObject;
      for (let i = 0; i < this.usercontributions.length; i++) {
        this.usercontributions[i]['FirstName'] = this.usercontributions[i].MstUser.FirstName;
        this.usercontributions[i]['LastName'] = this.usercontributions[i].MstUser.LastName
      }
      this.dataSource = new MatTableDataSource(this.usercontributions);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }

  BindContributionuser() {
    this.cs.contributionuser().subscribe((res: any) => {
      this.ngxloader.start()
      this.usercontributions = res.ReturnObject;
      for (let i = 0; i < this.usercontributions.length; i++) {
        this.usercontributions[i]['FirstName'] = this.usercontributions[i].MstUser.FirstName;
        this.usercontributions[i]['LastName'] = this.usercontributions[i].MstUser.LastName
      }
      this.dataSource = new MatTableDataSource(this.usercontributions);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  addContribution(Id: number) {
    this.usercontribution.Id = Id;
    const dialogRef = this.dailog.open(ContributiondailognComponent, {
      data: { value: this.usercontribution }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.BindContribution();
    });
  }

  editcontribution(row: any) {
    const dialogRef = this.dailog.open(ContributiondailognComponent, {
      data: { value: row }
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.BindContribution();
      this.CalculateContribution(row.UserId);
    });
  }

  CalculateContribution(userid:any){
    this.cs.CalculateContribution(userid).subscribe((res: any) => {
      this.ngxloader.start();
      this.BindContribution();
      this.ngxloader.stop();
    }, error => {
      this.BindContribution();
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }
}
