import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Group } from '../models/group';

@Component({
  selector: 'app-super-admin-dashboard',
  templateUrl: './super-admin-dashboard.component.html',
  styleUrls: ['./super-admin-dashboard.component.css']
})
export class SuperAdminDashboardComponent implements OnInit {
  groups: Group;
  gid: number;

  constructor(private cs: CommonService, public route: Router) {
  }

  ngOnInit() {
    this.cs.getGroups().subscribe((res: any) => {
      this.groups = res.ReturnObject;
      let str = this.route.url.split(';');
      if(str.length > 1)
        this.gid = Number(str[1].split("=")[1]);
    }, error => {
      this.cs.showSnackBar('danger', error.DetailsError);
    });
  }

  onchange(event) {
    this.gid = event.value
    this.route.navigate(['admin/super-admin-dashboard/home-sadmin',{
        gid: this.gid,
      }]);
  }
}
