import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/models/user';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ContributiondailognComponent } from '../contributiondailogn/contributiondailogn.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'src/app/service/common.service';
import { error_messege } from 'src/app/models/error_massege'
import { Loanrepayment } from 'src/app/models/loanrepayment';

@Component({
  selector: 'app-repaymentdailog',
  templateUrl: './repaymentdailog.component.html',
  styleUrls: ['./repaymentdailog.component.css']
})
export class RepaymentdailogComponent implements OnInit {

  user: User;
  users: User[] = [];
  fg: FormGroup;
  Repayment: Loanrepayment;
  Repayments: Loanrepayment[] = [];
  PaymentYear: any[];
  PaymentMonth: any[];
  error_messege = error_messege;
  records: any[];
  selecteduser: any;
  datavalue: any = 0;
  pdata: any = 0;
  idata: any = 0;
  loano: any;
  loani: any;
  name: string;
  loantype: any;
  // state: string = "";
  TotalPaid: number = 0;
  constructor(public dialogRef: MatDialogRef<ContributiondailognComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private cs: CommonService, private fb: FormBuilder, private ngxloader: NgxUiLoaderService) {
    this.PaymentYear = CommonService.PaymentYear;
    this.PaymentMonth = CommonService.PaymentMonth;
    this.selecteduser = this.data.loandetail[0].MstUser.Id;
    this.name = this.data.loandetail[0].MstUser.FirstName + " " + this.data.loandetail[0].MstUser.LastName;;
    this.fg = this.fb.group({
      Id: [''],
      UserId: [''],
      LoanId: [''],
      GroupId: [''],
      PaymentDate: ['', [Validators.required]],
      LoanOutstanding: [''],
      InterestOwned: [''],
      InterestRate: [''],
      InterestPaid: [''],
      LoanRepay: [''],
      MonthNo: [''],
      repaymentTemp:['', [Validators.required, Validators.pattern(/^(\d*\.)?\d+$/)]]
    })
  }
  ngOnInit() {
    this.BindUser();
    this.Repayment = this.data.value;
    if (this.Repayment.Id > 0) {
      this.loano = this.Repayment.LoanOutstanding;
      this.loani = this.Repayment.InterestOwned;
      this.idata = this.Repayment.InterestPaid;
      this.cs.getSetting().subscribe((res1: any) => {
        this.ngxloader.start();
        this.loantype = res1.ReturnObject[0].LoanType;
        // this.state = this.loantype == 'Fixed Interest' ? "fix" : "reduce";
        this.fg.patchValue({
          Id: this.Repayment.Id,
          UserId: this.name,
          LoanId: this.Repayment.LoanId,
          GroupId: this.Repayment.GroupId,
          PaymentDate: this.Repayment.PaymentDate,
          LoanOutstanding: this.loano,
          InterestOwned: this.Repayment.InterestOwned,
          InterestRate: this.Repayment.InterestRate,
          InterestPaid: this.idata,
          LoanRepay: this.Repayment.LoanRepay,
          MonthNo: this.Repayment.MonthNo,
          repaymentTemp : this.Repayment.LoanRepay + this.idata
        });
        this.ngxloader.stop();
      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured');
      })
    } else {
      this.cs.showSnackBar('danger', 'No record found.');
    }
  }

  get f() {
    return this.fg;
  }
  BindUser() {
    this.cs.userlist().subscribe((res: any) => {
      this.users = res.ReturnObject;
    })
  }

  onchangeloanrepayInterest() {
    var obj = this.fg.value;
    var calulatedInterest;
    var loanout;
    var calulatedInterestown;
    var loanint = (this.fg.value.repaymentTemp > this.data.loandetail[0].InterestOwned) 
                   ?((this.fg.value.repaymentTemp - this.data.loandetail[0].InterestOwned) > this.data.loandetail[0].InterestOwned
                   ?this.data.loandetail[0].InterestOwned : this.fg.value.repaymentTemp - this.data.loandetail[0].InterestOwned)
                   :(this.fg.value.repaymentTemp);
      calulatedInterest = loanint;
      obj.LoanRepay = (this.fg.value.repaymentTemp - loanint) < 0 
      ? loanint : (this.fg.value.repaymentTemp - loanint)  ;
      obj.InterestRate = obj.InterestRate == "" ? 0 : obj.InterestRate;
      loanout = this.data.loandetail[0].LoanOutstanding - obj.LoanRepay;
    if (this.loantype.toLowerCase() == String('Fixed Interest').toLowerCase()){
      calulatedInterestown = (this.data.loandetail[0].InterestOwned - calulatedInterest) ;
    }
    else{
      calulatedInterestown = (loanout * (obj.InterestRate / 100)) + (this.data.loandetail[0].InterestOwned - loanint);
    }
    
    this.datavalue = calulatedInterest;
    this.pdata = obj.LoanRepay;
    this.idata = calulatedInterest;
    this.loano = loanout;
    this.loani = calulatedInterestown;
    
    this.fg.patchValue({
      InterestPaid: calulatedInterest,
      InterestOwned: calulatedInterestown,
      LoanOutstanding: loanout,
      LoanRepay: this.pdata
    });
    this.TotalPaid = this.fg.value.repaymentTemp;
  }

  update() {
    this.Repayment = this.fg.value;
    this.Repayment.UserId = this.selecteduser;
    this.cs.Loanrepayment(this.Repayment).subscribe((res: any) => {
      this.ngxloader.start();
      this.dialogRef.close();
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }

  cancel() {
    this.dialogRef.close();
  }

}