import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { error_messege } from '../../models/error_massege';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CustomValidators } from 'src/app/modules/custom-validators';

@Component({
  selector: 'app-active-group',
  templateUrl: './active-group.component.html',
  styleUrls: ['./active-group.component.scss']
})
export class ActiveGroupComponent implements OnInit {

  error_messege = error_messege;
  userData: any;
  fg: FormGroup;
  router: string;
  state: string = "";
  constructor(private fb: FormBuilder, private cs: CommonService, private route: Router, private ngxloader: NgxUiLoaderService) {
    this.fg = this.fb.group({
      Email: ['', [Validators.compose([Validators.required, Validators.email, CustomValidators.trimValidator])]],
      OTPCode: [''],
    });
    this.fg.controls.Email.valueChanges.subscribe((val) => {
      this.fg.patchValue(
        {
          Email: val.trim()
        },
        { emitEvent: false })
    });

  }

  ngOnInit() {
  }


  get f() {
    return this.fg;
  }

  Activate() {
    this.cs.GetActivateGroup(this.fg.value.Email, this.fg.value.OTPCode).subscribe((res: any) => {
      if (res) {
        this.cs.showSnackBar('success', 'Group Activation successfuly');
      } else {
        this.cs.showSnackBar('danger', 'please enter correct activation code');
      }
    })
  }

}
