import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-loan-complete-msg',
  templateUrl: './loan-complete-msg.component.html',
  styleUrls: ['./loan-complete-msg.component.css']
})
export class LoanCompleteMsgComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LoanCompleteMsgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private cs: CommonService, private fb: FormBuilder, private ngxloader: NgxUiLoaderService) { }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close();
  }

}
