import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../service/common.service';
import { User } from '../models/user';

@Component({
  selector: 'app-dashboard ',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  selectedItem: any;
  opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  router: string;
  watcher: Subscription;
  FirstName: string;
  LastName: string;
  GroupName: string;
  state: string = "";
  constructor(private route: Router, media: MediaObserver, private ngxloader: NgxUiLoaderService, public cs: CommonService) {
    if (localStorage.getItem("adminSessionData") != null) {
      this.route.navigate(['admin']);
    }
    this.watcher = media.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
        this.opened = false;
        this.over = 'over';
      } else {
        this.opened = true;
        this.over = 'side';
      }
    });

    this.router = route.url;
    this.cs.fillSession();
    if (this.cs.userSession.Roles.RoleName == "Group Admin") {
      this.state = "admin";
    } else {
      this.state = "user";
    }
  }



  ngOnInit() {

    this.FirstName = this.cs.userSession.FirstName;
    this.LastName = this.cs.userSession.LastName;
    this.GroupName = this.cs.userSession.Groups.GroupName;
  }

  logout() {
    localStorage.clear();

    this.route.navigate(['login']);

  }

  listClick(event, newValue) {
    this.selectedItem = newValue;  // don't forget to update the model here
    // ... do other stuff here ...
  }
}
