import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { HomeComponent } from '../dashboard/home/home.component';
import { ProductComponent } from '../dashboard/product/product.component';

import { DefaultComponent } from '../default/default.component';
import { RegisterComponent } from '../default/register/register.component';
import { LoginComponent } from '../default/login/login.component';
import { UserComponent } from '../dashboard/user/user.component';
import { RolesComponent } from '../dashboard/roles/roles.component';
import { UsercontributionComponent } from '../dashboard/usercontribution/usercontribution.component';
import { LoandetailComponent } from '../dashboard/loandetail/loandetail.component';
import { LoanrepaymentComponent } from '../dashboard/loanrepayment/loanrepayment.component';
import { AuthGuard } from '../guard/auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { UserreportComponent } from '../dashboard/report/userreport/userreport.component';
import { PenaltyreportComponent } from '../dashboard/report/penaltyreport/penaltyreport.component';
import { SettingComponent } from '../dashboard/setting/setting.component';
import { CardsComponent } from '../dashboard/cards/cards.component';
import { AdminComponent } from '../admin/admin.component';
import { IndexComponent } from '../admin/index/index.component';
import { GroupuserComponent } from '../admin/groupuser/groupuser.component';
import { GroupComponent } from '../admin/group/group.component';
import { AdminloginComponent } from '../default/adminlogin/adminlogin.component';
import { ContributionHistoryComponent } from '../dashboard/contribution-history/contribution-history.component';
import { PayoutcalculatorComponent } from '../dashboard/payoutcalculator/payoutcalculator.component';
import { ProfileComponent } from '../dashboard/profile/profile.component';
import { ChangePasswordComponent } from '../dashboard/change-password/change-password.component';
import { ActiveGroupComponent } from '../default/active-group/active-group.component';
import { LoanpdfComponent } from '../dashboard/loanpdf/loanpdf.component';
import { RecoverPasswordComponent } from '../default/recover-password/recover-password.component';
import { ApplyloanComponent } from '../dashboard/applyloan/applyloan.component';
import { LoanHistoryComponent } from '../dashboard/loan-history/loan-history.component';
import { OverdueLoanComponent } from '../dashboard/overdue-loan/overdue-loan.component';
import { SuperAdminDashboardComponent } from '../super-admin-dashboard/super-admin-dashboard.component';
import { HomeSadminComponent } from '../super-admin-dashboard/home-sadmin/home-sadmin.component';
import { CardsSadminComponent } from '../super-admin-dashboard/cards-sadmin/cards-sadmin.component';
import { ForbiddenComponent } from '../dashboard/forbidden/forbidden.component';
import { ForbiddenAdminComponent } from '../admin/forbidden-admin/forbidden-admin.component';
import { MemberlistComponent } from '../dashboard/memberlist/memberlist.component';

const routes: Routes = [

  {
    path: '', component: LoginComponent, children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },


    ]
  },

  {
    path: 'default', component: DefaultComponent, children: [
      { path: 'register', component: RegisterComponent },
      { path: 'activegroup', component: ActiveGroupComponent },
      { path: 'recoverpassword', component: RecoverPasswordComponent },


    ]
  },

  { path: 'register', component: RegisterComponent },

  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] }, children: [
      { path: '', component: HomeComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'user', component: UserComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin'] } },
      { path: 'memberlist', component: MemberlistComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'role', component: RolesComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin'] } },
      { path: 'usercontribution', component: UsercontributionComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'loandetail', component: LoandetailComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'overdueloan', component: OverdueLoanComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'loan-history', component: LoanHistoryComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'loanrepayment', component: LoanrepaymentComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'userreport', component: UserreportComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'penaltyreport', component: PenaltyreportComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'setting', component: SettingComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'cards', component: CardsComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'contributionhistory', component: ContributionHistoryComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'payoutcalculator', component: PayoutcalculatorComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'loanpdf/:Id/:uid', component: LoanpdfComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'applyloan', component: ApplyloanComponent, canActivate: [AuthGuard], data: { roles: ['Group Admin', 'Group User'] } },
      { path: 'forbidden', component: ForbiddenComponent },
    ]
  },
  // { path: 'forbidden', component: ForbiddenComponent},
  // {
  //   path: 'super-admin-dashboard', component: SuperAdminDashboardComponent, children: [
  //     { path: 'home-sadmin/:gid', component: HomeSadminComponent },
  //     { path: 'home-sadmin', component: HomeSadminComponent },
  //     { path: 'cardssadmin', component: CardsSadminComponent },

  //   ]
  // },
  // { path: 'loanpdf/Id/uid', component: LoanpdfComponent },
  {
    path: 'adminlogin', component: AdminloginComponent, children: [
      { path: 'adminlogin', component: AdminloginComponent },

    ]
  },
  {
    path: 'admin', component: AdminComponent, canActivate: [AuthGuard], data: { roles: ['SuperAdmin'] }, children: [
      // { path: '', component: IndexComponent },
      { path: '', component: GroupComponent, canActivate: [AuthGuard], data: { roles: ['SuperAdmin'] } },
      { path: 'group', component: GroupComponent, canActivate: [AuthGuard], data: { roles: ['SuperAdmin'] } },
      { path: 'groupuser', component: GroupuserComponent, canActivate: [AuthGuard], data: { roles: ['SuperAdmin'] } },
      {
        path: 'super-admin-dashboard', component: SuperAdminDashboardComponent, canActivate: [AuthGuard], data: { roles: ['SuperAdmin'] }, children: [
          { path: 'home-sadmin/:gid', component: HomeSadminComponent, canActivate: [AuthGuard], data: { roles: ['SuperAdmin'] } },
          { path: 'home-sadmin', component: HomeSadminComponent, canActivate: [AuthGuard], data: { roles: ['SuperAdmin'] } },
          { path: 'cardssadmin', component: CardsSadminComponent, canActivate: [AuthGuard], data: { roles: ['SuperAdmin'] } },

        ]
      },
      { path: 'forbidden-admin', component: ForbiddenAdminComponent },
    ]
  },
  // { path: 'alogin', component: AloginComponent }
];

@NgModule({
  imports: [BrowserModule, RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
