import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { MatTableDataSource } from '@angular/material';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-userreport',
  templateUrl: './userreport.component.html',
  styleUrls: ['./userreport.component.css']
})
export class UserreportComponent implements OnInit {

  data: any[];
  user: any[];
  dataSource: any;
  displayedColumns: string[] = [];
  constructor(private cs: CommonService, private ngxloader: NgxUiLoaderService) { }

  ngOnInit() {
   
    
    this.data = [] //TABLE DATASOURCE

    //GET SOMETHING FROM SERVICE 
    this.cs.userreport().subscribe((res: any) => {
      this.ngxloader.start();
      this.user = res;

      // //FILL TABLE DATASOURCE 
      // var obj = {};

      // for (let i in this.user) {
      //   for (let v of this.user) {
      //     obj[v.key] = v.value;
      //   }
      //   this.data.push(obj);
      //   obj = {};
      // }


      //CREATE DISPLAYED COLUMNS DYNAMICALLY
      this.displayedColumns = [];
      for (let v in this.user[0]) {
        this.displayedColumns.push(v);
      }

      // INITIALIZE MatTableDataSource
      this.dataSource = new MatTableDataSource(this.user);
      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }
  exportAsXLSX(): void {
    this.cs.exportAsExcelFile(this.user, 'user');
  }

}
