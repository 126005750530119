import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/models/user';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { CommonService } from 'src/app/service/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GroupuserdailogComponent } from './groupuserdailog/groupuserdailog.component';
import { Group } from 'src/app/models/group';
import { Router } from '@angular/router';

@Component({
  selector: 'app-groupuser',
  templateUrl: './groupuser.component.html',
  styleUrls: ['./groupuser.component.css']
})
export class GroupuserComponent implements OnInit {

  value: string;


  user: User = new User();
  users: User[] = [];

  displayedColumns: string[] = ['Id', 'GroupID', 'AccountNumber', 'FirstName', 'LastName', 'Roles', 'Email', 'Gender', 'CellNumber', 'Active', 'action'];
  dataSource: MatTableDataSource<User>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(private cs: CommonService, public dailog: MatDialog, private ngxloader: NgxUiLoaderService, private route: Router) {

    if (localStorage.getItem("adminSessionData") == null) {
      this.route.navigate(['login']);
    }
  }

  ngOnInit() {
    this.Bindusers();
  }

  Bindusers() {
    this.cs.adminlist().subscribe((users: any) => {
      this.ngxloader.start();
      if (users.ReturnObject == null) {
        this.cs.showSnackBar('danger', 'no data found');
        this.ngxloader.stop();
      } else {
        this.users = users.ReturnObject;
        this.patchMatchTable();
        this.ngxloader.stop();
      }

      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    });
  }

  patchMatchTable() {
    this.dataSource = new MatTableDataSource(this.users);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  edituser(row: any) {
    // this.ngxloader.start();
    const dialogRef = this.dailog.open(GroupuserdailogComponent, {
      height: "600px",
      width: "500px",
      data: { value: row }
    });
    // this.ngxloader.stop();
    dialogRef.afterClosed().subscribe(result => {
      this.Bindusers();
    });
  }


  adduser(userid: number) {
    this.user.Id = userid;
    // this.ngxloader.start();
    const dialogRef = this.dailog.open(GroupuserdailogComponent, {
      height: "600px",
      width: "500px",
      data: { value: this.user }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.Bindusers();
    });
    // this.ngxloader.stop();
  }

}
