import { Component, AfterViewInit, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { error_messege } from 'src/app/models/error_massege'
import { Group } from 'src/app/models/group';
import { User } from 'src/app/models/user';
import { CompareValidation } from 'src/app/models/validators';
import { MatTabChangeEvent } from '@angular/material';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/modules/custom-validators';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements AfterViewInit {
  Groups: Group;
  user: FormGroup;
  groups: FormGroup;
  setting: FormGroup;
  activation: FormGroup;
  genders: string[] = ['Male', 'Female'];
  error_messege = error_messege;
  selectedIndex: number = 0;
  minDate: Date;
  maxNewDate = new Date();
  constructor(private fb: FormBuilder, private cs: CommonService, private elementRef: ElementRef, private route: Router) {
    this.minDate = new Date();
    this.maxNewDate.setUTCFullYear(this.maxNewDate.getUTCFullYear() - 5);
    this.user = this.fb.group({

      FirstName: ['', [Validators.required, Validators.pattern('[A-Za-z ]*')]],
      LastName: ['', [Validators.required, Validators.pattern('[A-Za-z ]*')]],
      Email: ['', [Validators.compose([Validators.required, Validators.email, CustomValidators.trimValidator])]],
      // Password: ['', [Validators.required, Validators.pattern('/^[a-zA-Z0-9!@#$%^&*()+]*/')]],
      // Password: ['', [Validators.required, Validators.pattern('^(.{8,12}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9!@#$%^&*()+]*)$')]],

      Password: ['', Validators.compose([
        Validators.required,
        // check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true
        }),
        // check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        // check whether the entered password has a lower case letter
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        // check whether the entered password has a special character
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          // /[ !@#$%^&*()_+-]/,
          {
            hasSpecialCharacters: true
          }
        ),
        Validators.minLength(8)
      ])],
      confirm_password: ['', [Validators.required]],
      DOB: [''],

      // Address:[''],
      // OtpCode:[''],
      Gender: [''],
      CellNumber: ['', [Validators.required]],
      Active: [true],
      // Active: ['', [(control) => {
      //   return !control.value ? { 'required': true } : null;
      // }]],


    }, { validator: CompareValidation });

    this.groups = this.fb.group({
      GroupName: [''],
      GroupMembers: ['']
    });
    this.user.controls.Email.valueChanges.subscribe((val) => {
      this.user.patchValue(
        {
          Email: val.trim()
        },
        { emitEvent: false })
    });

    this.setting = this.fb.group({
      InterestRate: [''],
      SettingStartDate: [''],
      SettingEndDate: [''],
      SettingInterestRate: [''],
      SettingLoanEligibility: [''],
      SettingSharePrice: [''],
      InterestType: ['']

    })

    this.activation = this.fb.group({
      uName: ['', [Validators.required, Validators.email]],
      OTPCode: ['', [Validators.required]],
    })
  }

  ngOnInit() {


  }
  get f() {
    return this.user;
  }

  reg() {

    if (this.groups.invalid) {
      this.cs.showSnackBar('danger', 'filled all values of group details');
    } else if (this.user.invalid) {
      this.cs.showSnackBar('danger', 'filled all values of user details');
    } else if (this.setting.invalid) {
      this.cs.showSnackBar('danger', 'filled all values of user details');
    } else {

      var obj: User = this.user.value;
      obj.SettingStartDate = this.setting.value.SettingStartDate;
      obj.SettingEndDate = this.setting.value.SettingEndDate;
      obj.SettingInterestRate = this.setting.value.SettingInterestRate;
      obj.SettingLoanEligibility = this.setting.value.SettingLoanEligibility;
      obj.SettingSharePrice = this.setting.value.SettingSharePrice;
      obj.InterestType = this.setting.value.InterestType;
      obj.Groups = this.groups.value;
      this.activation.patchValue({
        uName: obj.Email
      })
      this.cs.GetEmailExist(obj.Email).subscribe((res: any) => {
        if (res == true) {
          this.cs.showSnackBar('danger', 'The selected Email is already registered in the system');
        } else {
          this.cs.RegisterGroup(obj).subscribe(() => {
            this.cs.showSnackBar('success', 'Get your OTP from your mail box. You should have a mail from info@digitalpluszm.org');
            this.selectedIndex += 1
          })

        }
      })
    }


  }

  Activate() {
    this.cs.GetActivateGroup(this.activation.value.uName, this.activation.value.OTPCode).subscribe((res: any) => {
      if (res) {
        this.cs.showSnackBar('success', 'Group Activation successfuly');
        this.route.navigate(["login"]);
      } else {
        this.cs.showSnackBar('danger', 'please enter correct activation code');
      }
    })
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedIndex = tabChangeEvent.index;
  }
  public nextStep() {
    this.selectedIndex += 1;
  }
  public previousStep() {
    this.selectedIndex -= 1;
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = `url('assets/img/3.jpg')`;
  }
}
