import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { error_messege } from '../../models/error_massege';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  error_messege = error_messege;
  userData: any;
  fg: FormGroup;
  router: string;
  constructor(private fb: FormBuilder, private cs: CommonService, private route: Router, private ngxloader: NgxUiLoaderService) {
    this.fg = this.fb.group({
      uName: ['', [Validators.required, Validators.email]],
     
    })
    //Password  Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/)
    this.router = route.url;
  }

  ngOnInit() {

  }

  get f() {
    return this.fg;
  }


sendpass(){
  this.cs.GetForgotPassword(this.fg.value.uName).subscribe((res:any)=>{
      this.cs.showSnackBar('success', res.DetailsError);
  });
}
}
