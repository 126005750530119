import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepickerInputEvent } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { Loandetail } from 'src/app/models/loandetails';
import { User } from 'src/app/models/user';
import { error_messege } from 'src/app/models/error_massege'
import { Loanrepayment } from 'src/app/models/loanrepayment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment';

@Component({
  selector: 'app-loanapplicationdailog',
  templateUrl: './loanapplicationdailog.component.html',
  styleUrls: ['./loanapplicationdailog.component.css']
})
export class LoanapplicationdailogComponent implements OnInit {

  users: User[] = [];
  error_messege = error_messege;
  fg: FormGroup;
  loandetail: Loandetail;
  loandetails: Loandetail[] = [];
  paymentYear: any[];
  paymentMonth: any[];
  datavalue: any = 0;
  pdata: any = 0;
  idata: any = 0;
  state: string = "";
  loanid: any;
  userid: any;
  loaninterestrate: any;
  amt: any;
  minDate: Date;
  constructor(
    public dialogRef: MatDialogRef<LoanapplicationdailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private cs: CommonService,
    private ngxloader: NgxUiLoaderService
  ) {

    this.minDate = new Date();
    dialogRef.disableClose = true;
    this.cs.fillSession();
    this.paymentYear = CommonService.PaymentYear;
    this.paymentMonth = CommonService.PaymentMonth;

    this.fg = this.fb.group({
      UserId: [this.cs.userSession.Id, [Validators.required]],
      GroupID: [this.cs.userSession.GroupID],
      InterestRate: ['', [Validators.required, Validators.pattern(/^(\d*\.)?\d+$/)]],
      Tenure: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      Principal: ['', [Validators.required, Validators.pattern(/^(\d*\.)?\d+$/)]],
      LoanStartDate: ['', [Validators.required]],
      LoanEndDate: [''],
      // LoanStatus: [''],
      PaymentMonth: [''],
      PaymentYear: [''],
      OutstnadingPrincipal: [''],
      OutstandingInterest: [''],
      TotalInterest: [''],
      LoanStatus: ['false']
    })

  }

  ngOnInit() {
    this.cs.userlist().subscribe((users: any) => {
      this.users = users.ReturnObject;
    })
    this.cs.getSetting().subscribe((res: any) => {
      this.loaninterestrate = res.ReturnObject[0].InterestRate;


      this.loandetail = this.data.value
      if (this.loandetail.Id == 0) {
        this.state = "add";
        this.fg.patchValue({
          InterestRate: this.loaninterestrate
        })
      } else {
        this.state = "edit"

        this.fg = this.fb.group({
          Id: [this.loandetail.Id],
          UserId: [this.cs.userSession.Id],
          GroupID: [this.cs.userSession.GroupID],
          TotalInterest: [this.loandetail.TotalInterest],
          InterestRate: [this.loandetail.InterestRate],
          Tenure: [this.loandetail.Tenure, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
          Principal: [this.loandetail.Principal, [Validators.required, Validators.pattern(/^(\d*\.)?\d+$/)]],
          LoanStartDate: [this.loandetail.LoanStartDate, [Validators.required]],
          LoanEndDate: [this.loandetail.LoanEndDate],
          OutstnadingPrincipal: [this.loandetail.OutstnadingPrincipal],
          OutstandingInterest: [this.loandetail.OutstandingInterest],
        })

        this.datavalue = this.fg.value.TotalInterest;
        this.pdata = this.fg.value.OutstnadingPrincipal;
        this.idata = this.fg.value.OutstandingInterest;
      }
    })

  }



  get f() {
    return this.fg;
  }


  onclick() {
    var addloan: Loandetail = this.fg.value;
    this.fg.value.TotalInterest = this.datavalue;
    this.fg.value.OutstandingInterest = this.idata;
    this.fg.value.OutstnadingPrincipal = this.pdata;

    this.userid = this.fg.value.UserId;
    this.amt = this.fg.value.Principal;

    if (this.state == 'add') {
      this.cs.loanEligibility(this.userid, this.amt).subscribe((res: any) => {
        this.ngxloader.start();
        if (res == true) {
          this.cs.GetAvaliableFundCheck(this.amt).subscribe((res1: any) => {
            if (res1 == true){
          addloan.CreatedBy = this.cs.userSession.Id;
          addloan.ModifiedBy = this.cs.userSession.Id;
          this.cs.userLoanAddByUser(addloan).subscribe((res: any) => {

          }, error => {
            this.ngxloader.stop();
            this.cs.showSnackBar('danger', 'Error Occured : User Record Not Created ');
          })
        }
        else{
          this.cs.showSnackBar('danger', 'Loan amount should not be greater than vaialble funds');
        }
        }, error => {
          this.ngxloader.stop();
          this.cs.showSnackBar('danger', 'Error Occured');
        })
        } else {
          this.cs.showSnackBar('danger', 'User is not eligible to take loan more than savings');
        }
        this.ngxloader.stop();
      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured');
      })


    } else {

      this.state = "edit";
      this.cs.loanEligibility(this.userid, this.amt).subscribe((res: any) => {
        this.ngxloader.start();
        if (res == true) {
          this.cs.GetAvaliableFundCheck(this.amt).subscribe((res1: any) => {
            if (res1 == true){
          addloan.CreatedBy = this.cs.userSession.Id;
          addloan.ModifiedBy = this.cs.userSession.Id;
          this.cs.userLoanEdit(addloan).subscribe((res1: any) => {
            this.ngxloader.start();
            if (res1) {
              this.cs.showSnackBar('success', 'User Record Updated successfully');
              this.dialogRef.close();
            } else {
              this.cs.showSnackBar('danger', 'User Record Not Updated successfully');
            }
            this.ngxloader.stop();
          }, error => {
            this.ngxloader.stop();
            this.cs.showSnackBar('danger', 'Error Occured : User Record Not Updated');
          })
        }
        else{
          this.cs.showSnackBar('danger', 'Loan amount should not be greater than vaialble funds');
        }
        }, error => {
          this.ngxloader.stop();
          this.cs.showSnackBar('danger', 'Error Occured');
        })
        } else {
          this.cs.showSnackBar('danger', 'User is not eligible to take loan more than savings');
        }
        this.ngxloader.stop();
      }, error => {
        this.ngxloader.stop();
        this.cs.showSnackBar('danger', 'Error Occured');
      })
    }

  }

  onchangePrincipalInterest() {
    var obj = this.fg.value;
    var calulatedInterest;
    obj.Principal = obj.principal == "" ? 0 : obj.Principal;
    obj.InterestRate = obj.InterestRate;
    calulatedInterest = obj.Principal * (obj.InterestRate / 100);
    this.datavalue = calulatedInterest;
    this.pdata = obj.Principal;
    this.idata = calulatedInterest;
    this.fg.patchValue({
      OutstnadingPrincipal: this.pdata,
      OutstandingInterest: this.idata,
      TotalInterest: this.idata
    })
  }

  cancel() {
    this.dialogRef.close();
  }

  datechangeEvent(event) {
    var date = moment(this.fg.value.LoanStartDate);
    var selectedTenureDate = moment(date.add(this.fg.value.Tenure, 'months'));
    this.fg.patchValue({
      LoanEndDate: selectedTenureDate.toDate()
    })
    // this.fg.value.LoanEndDate = selectedTenureDate.toDate();
    var date1 = new Date();
  }

}
