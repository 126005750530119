import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-payoutcalculator',
  templateUrl: './payoutcalculator.component.html',
  styleUrls: ['./payoutcalculator.component.css']
})

export class PayoutcalculatorComponent implements OnInit {
  payout: any;
  uid: any;
  fg: FormGroup;
  data: any[];
  constructor(private fb: FormBuilder, private cs: CommonService, private ngxloader: NgxUiLoaderService) {
    this.cs.fillSession();
    this.fg = this.fb.group({
      TotalPayout: [''],
      CurrentSaving: [''],
      CurrentInterestCumulative: [''],
      NoOfMonthSaved: [''],
      NoOfMonthsRemaining: [''],
      InterestRate: [''],
      TotalSavingRequired: [''],
      NewMonthlySaving: ['']
    })
  }

  ngOnInit() {
   
  }

  show() {
  
    var obj = this.fg.value;
    this.payout = obj.TotalPayout;
    this.uid = this.cs.userSession.Id;
    this.cs.userPayOutCalculate(this.uid, this.payout).subscribe((res: any) => {
      this.ngxloader.start();
      this.data = res.ReturnObject;

      this.fg = this.fb.group({
        TotalPayout: [this.data[0].TotalPayout],
        CurrentSaving: [this.data[0].CurrentSaving],
        CurrentInterestCumulative: [this.data[0].CurrentInterestCumulative],
        NoOfMonthSaved: [this.data[0].NoOfMonthSaved],
        NoOfMonthsRemaining: [this.data[0].NoOfMonthsRemaining],
        InterestRate: [this.data[0].InterestRate],
        TotalSavingRequired: [this.data[0].TotalSavingRequired],
        NewMonthlySaving: [this.data[0].NewMonthlySaving],
      })


      this.ngxloader.stop();
    }, error => {
      this.ngxloader.stop();
      this.cs.showSnackBar('danger', 'Error Occured');
    })
  }

}
