import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { CommonService } from 'src/app/service/common.service';
import { Role } from 'src/app/models/roles';
import { RolesdailogComponent } from 'src/app/shared/rolesdailog/rolesdailog.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {

  value: string;


  role: Role = new Role();
  roles: Role[] = [];
  displayedColumns: string[] = ['Id', 'RoleName', 'Active'];
  dataSource: MatTableDataSource<Role>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(private cs: CommonService, public dailog: MatDialog, private ngxloader: NgxUiLoaderService) { }

  ngOnInit() {
  
    this.BindRoles();
 
  }

  BindRoles() {
    var gid = this.cs.userSession.GroupID;
    this.cs.roleslist(gid).subscribe((roles: any) => {

      this.roles = roles.ReturnObject;
      this.patchMatchTable();

    })
  }

  patchMatchTable() {
    this.dataSource = new MatTableDataSource(this.roles);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  // editrole(row: any) {
  //   const dialogRef = this.dailog.open(RolesdailogComponent, {
  //     height: '55vh',
  //     width: '55vw',
  //     data: { value: row }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.cs.userlist().subscribe((res: any) => {
  //       this.roles = res.ReturnObject;
  //     })
  //   });
  // }


  addrole(Id: any) {
    this.role.Id = Id;

    const dialogRef = this.dailog.open(RolesdailogComponent, {

      data: { value: this.role }
    });

    dialogRef.afterClosed().subscribe(result => {

      this.BindRoles();
      this.patchMatchTable();
    });
  
  }

  editrole(row: any) {
    
    const dialogRef = this.dailog.open(RolesdailogComponent, {

      data: { value: row }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.BindRoles();
      this.patchMatchTable();
    });
   
  }
}


